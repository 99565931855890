"use client";
import FacilityInformation from "@/app/components/Order/FacilityInformation";
import UnitInformation from "@/app/components/Order/UnitInformation";
import { clientLogError } from "@/app/helpers/clientLoggingHelper";
import { formatDateByCountry } from "@/app/helpers/dateHelper";
import { iOrderStoragePage } from "@/app/models/pageTypes/orderStorageStepPage";
import { doUseOrderStorageStore } from "@/stores/order-storage-store";
import { useRouter } from "next/navigation";
import { useEffect, useState } from "react";
import useStore from "@/stores/use-store";
import { useShallow } from "zustand/react/shallow";
import { parse } from "path";

interface OrderStoragePageProps {
  pageData: iOrderStoragePage;
}

export default function OrderStorageStep2ReviewYourInfo({
  pageData,
}: OrderStoragePageProps) {
  let translation = pageData.requestContext.translations;
  const facilityData = pageData.properties.facility;
  const siteUnit = pageData.properties.unit;
  const unitFeatures = pageData.properties.unitFeatures;
  const [trackingValuesAreSet, setTrackingValuesAreSet] = useState(false);

  const order = doUseOrderStorageStore(useShallow((state) => state));
  const setTrackingValue = useStore(
    doUseOrderStorageStore,
    useShallow((state) => state)
  )?.setTrackingValue;

  useEffect(() => {
    if (setTrackingValue) {
      const storageRentString = order?.tracking?.storageValue?.toString() || siteUnit?.MonthRate?.toString() || "0";

      if (!order?.tracking?.storageValue) {
        setTrackingValue("storageValue", storageRentString);
      }
      
      const storageRent = storageRentString ? parseFloat(storageRentString.toString().replace(",", ".")) : 0;
      const ordinaryMonthTotal = parseFloat(order.insurance.insurancePricePerMonth) + storageRent;

      setTrackingValue("firstMonthValue", order.payment.amount);
      setTrackingValue("ordinaryMonthValue", ordinaryMonthTotal.toString());
      setTrackingValue("clientType", order.user.isCompany ? "company" : "private");
      setTrackingValue("insurance", `${order.insurance.insuranceSize} ${order.insurance.insurancePricePerMonth} ${translation.general.priceUnit}/${translation.general.shortMonth} - ${order.insurance.insuranceDescription}`);
      setTrackingValuesAreSet(true);
    }
  }, [setTrackingValue]);

  useEffect(() => {
    if (trackingValuesAreSet) {
      try {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: "booking_step",
          step: "review_order",
          site_code: order.tracking.siteCode,
          site_id: order.tracking.siteId,
          site_name: order.tracking.siteName,
          size: order.tracking.size,
          booking_id: order.tracking.bookingId,
          currency: order.tracking.currency,
          storage_value: order.tracking.storageValue,
          first_month_value: order.tracking.firstMonthValue,
          ordinary_month_value: order.tracking.ordinaryMonthValue,
          client_type: order.tracking.clientType,
          insurance: order.tracking.insurance,

        })
      } catch (error) {
        clientLogError("OrderStorageStep2ReviewYourInfoPages", "Tracing", "Could not trace Order Step 2", error);
      }
    }
  }, [trackingValuesAreSet]);

  useEffect(() => {


    const handleBeforeUnload = (e: BeforeUnloadEvent) => {
      e.preventDefault();
      e.returnValue = "";
    };
    const handelPageHide = () => {
      fetch(`/api/unit/unReserveUnit`, {
        keepalive: true,
        method: "POST",
        headers: {
          "content-type": "application/json",
          Country: pageData.requestContext.country,
        },
        body: JSON.stringify({
          unitId: siteUnit?.Id,
          sizeId: siteUnit?.SizeId,
        }),
      });
    }
    window.addEventListener("beforeunload", handleBeforeUnload);
    window.addEventListener("pagehide", handelPageHide);



    return () => {
      window.removeEventListener("pagehide", handelPageHide);
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  return (
    <div className="">
      <div className="justify-items-center bg-gs-white rounded-3xl p-4 lg:p-6">
        <div className="w-full">
          <div className="inline-flex justify-between w-full">
            <h3 className="text-md font-bold">
              {translation.general?.storageUnit ?? "Storage"}
            </h3>
          </div>

          <div className="flex justify-between pt-2 pb-6">
            <div className="inline-flex items-center">
              <img
                src={`/assets/img/storageSizes/storage_${siteUnit?.Size.GroupNameShort}.png`}
                alt="Green Storage"
                width="48"
                height="48"
              />
              <h3 className="font-bold uppercase p-4">
                {siteUnit?.Size?.GroupName}
              </h3>
            </div>
            <div className="inline-flex items-center">
              <p>{order.unit.unitSizeM2} m²</p>
            </div>
          </div>

          {siteUnit && unitFeatures && (
            <div className="bg-gs-grey rounded-lg p-6">
              {/* <p className="font-bold">
                {translation.pages?.order?.storageDescription ??
                  "Storage information"}
                :
              </p> */}
              <p className="text-md font-bold mb-0">
                {pageData.properties.facility?.name}
              </p>
              <UnitInformation translation={translation} pageData={pageData} />
            </div>
          )}

          {/* {facilityData && (
            <div className="pt-6">
              <h4 className="font-bold">
                {translation.general?.facility ?? "Facility"}
              </h4>
              <FacilityInformation
                translation={translation}
                facility={facilityData}
              />
            </div>
          )} */}

          <div className="pt-6">
            <h4 className="font-bold">{translation.pages?.order.startDate}</h4>
            <p>
              {formatDateByCountry(
                new Date(order.date.startDate).toISOString(),
                pageData.requestContext.country
              )}
            </p>
          </div>

          <div className="">
            <h4 className="font-bold">{translation.pages?.order.insurance}</h4>
            <input
              type="text"
              readOnly
              value={`${order.insurance.insuranceSize} ${order.insurance.insurancePricePerMonth} ${translation.general.priceUnit}/${translation.general.shortMonth} - ${order.insurance.insuranceDescription}`}
              aria-haspopup="listbox"
              aria-disabled={false}
            />
          </div>
        </div>
      </div>

      <div className=" justify-items-center bg-gs-white rounded-3xl p-4 lg:p-6 mt-8">
        <div className="w-full">
          <div className="inline-flex justify-between w-full">
            <h3 className="text-md font-bold">
              {translation.pages?.order?.yourInformation ?? "Your information"}
            </h3>
          </div>
          <div className="user-details grid grid-cols-1 sm:grid-cols-2 gap-4">
            <div>
              <p className="font-bold">
                {translation.pages?.order.form.email ?? "Email"}
              </p>
              <p>{order.user.email}</p>
            </div>
            <div>
              <p className="font-bold">
                {translation.general.streetAddress ?? "Street address"}
              </p>
              <p>{order.user.streetAddress}</p>
              <p>
                {order.user.postalCode} {order.user.city}
              </p>
            </div>
            <div>
              <p className="font-bold">
                {translation.pages?.order.form.phoneNumber ?? "Phone number"}
              </p>
              <p>{order.user.phoneNumber}</p>
            </div>
            {order.user.isCompany && (
              <div>
                <p className="font-bold">
                  {translation.pages?.order.form.companyName ?? "Company name"}{" "}
                  &{" "}
                  {translation.pages?.order.form.organisationsNumber ??
                    "Org.nummer"}
                </p>
                <p>{order.user.companyName}</p>
                <p>{order.user.companyOrgNumber}</p>
              </div>
            )}
            {order.user.isCompany && order.user.isInvoice && (
              <>
                <div>
                  <p className="font-bold">
                    {translation.pages?.order.form.invoiceEmail ??
                      "Faktura email"}
                  </p>
                  <p>{order.user.invoiceEmail}</p>
                </div>
                <div>
                  <p className="font-bold">
                    {translation.pages?.order.form.invoiceRef ??
                      "Faktura referens"}
                  </p>
                  <p>{order.user.invoiceReference}</p>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
