import SmallTextLink from '@/app/components/SmallTextLink/SmallTextLink';
import { logError } from '@/app/helpers/azureBlobLogger';
import { iSmallTextLinkBlock } from '@/app/models/blockTypes/smallTextLinkBlock';
import { iRequestContext } from '@/app/models/contextModels/requestContext';

interface Props {
    block: iSmallTextLinkBlock;
    requestContext: iRequestContext;

}


export default function SmallTextLinkBlock({ block, requestContext }: Props) {
    try {
        return (
            <div className={`block relative px-6 xl:p-0`}>
                <SmallTextLink key={block.id} block={block} translations={requestContext.translations} />
            </div>
        );
    } catch (error) {
        logError("SmallTextLinkBlock.tsx", 'Error when generating SmallTextLinkBlock', `current url: ${requestContext.url}`, 'Error', error);
    }
}


