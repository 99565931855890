"use client"
import React, { useState, useEffect, useRef } from "react";
import { iBlogPage } from "@/app/models/pageTypes/blogPage";
import BlogPostItem from "@/app/components/BlogPostItem/BlogPostItem";
import { eFetchSpecifier } from "@/app/models/enum/fetchSpecifier";
import { iUmbracoGroupResponse } from "@/app/models/apiModels/umbracoGroupResponse";
import { usePathname, useRouter, useSearchParams } from "next/navigation";
import { iGetContentRequestModel } from "@/app/models/apiModels/requestModels/getContentRequestModel";
import { GetAbsoluteUrlClient, StripHtml } from "@/app/helpers/clientUtilityHelper";
import Loader from "../Loader/Loader";
import { clientLogError } from "@/app/helpers/clientLoggingHelper";
import { logInfo } from "@/app/helpers/azureBlobLogger";
import Pagination from "../Pagination/Pagination";
import { formatDateByCountry, isValidDate } from "@/app/helpers/dateHelper";

interface Props {
    pageId: string;
    translations: any;
    itemsPerPage: number;
    defaultImage: string;
    language: string;
    initialPage: number;
    country: string;
}

export default function BlogListing({ pageId, translations, itemsPerPage, defaultImage, language, initialPage, country }: Props) {
    logInfo("BlogListing.tsx", "BlogListing", `Data: pageId: ${pageId}, itemsPerPage: ${itemsPerPage}, defaultImage: ${defaultImage}, language: ${language}`);
    const router = useRouter();
    const [currentPage, setCurrentPage] = useState(initialPage);
    const [items, setItems] = useState<iBlogPage[]>([]);
    const [totalPages, setTotalPages] = useState(0);
    const [baseUrl, setBaseUrl] = useState("");
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const currentUrl = window.location.pathname;
        const pageMatch = currentUrl.match(/\/page\/(\d+)\/?$/);
        let newUrl;
        if (pageMatch) {
            newUrl = currentUrl.replace(/\/page\/(\d+)\/?$/, "");
        } else {
            newUrl = `${currentUrl}`;
        }
        setBaseUrl(newUrl);
    }, []);

    useEffect(() => {
        fetchItemsForPage(currentPage);
    }, [currentPage]);

    const fetchItemsForPage = async (page: number) => {
        await fetchItems((page - 1) * itemsPerPage, itemsPerPage);
    };

    const fetchItems = async (skip: number, take: number) => {
        logInfo("BlogListing.tsx", "fetchItems", `Fetching items: skip: ${skip}, take: ${take}`);
        setLoading(true);

        const requestModel: iGetContentRequestModel = {
            pageId: pageId,
            childrenAncestorOrDescendantsSpecifier: eFetchSpecifier.Descendants,
            previewMode: false,
            skip: skip,
            take: take,
            extraQuery: "sort=customCreatedDate:desc",
            language: language,
            contentType: "blogPage"
        };

        try {
            const response = await fetch('/api/content/getContent', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(requestModel),
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();
            const umbracoResponsemodel = data.content as iUmbracoGroupResponse;
            logInfo("BlogListing.tsx", "fetchItems", `Fetched items: ${umbracoResponsemodel.items.length} items`);
            const items = (umbracoResponsemodel.items ?? []) as iBlogPage[];
            setTotalPages(Math.ceil(umbracoResponsemodel.total / itemsPerPage));
            setItems(items);
        } catch (error) {
            clientLogError("BlogListing.tsx", "fetchItems", error);
        } finally {
            setLoading(false);
        }
    };

    const handleChangePage = (newPage: number) => {
        setCurrentPage(newPage);
        const currentUrl = window.location.pathname;

        const pageMatch = currentUrl.match(/\/page\/(\d+)\/?$/);
        let newUrl;
        if (pageMatch) {
            // Replace the digit with the new page number
            newUrl = currentUrl.replace(/\/page\/(\d+)\/?$/, `/page/${newPage}/`);
        } else {
            // Append "page/[newPage]/" to the URL
            newUrl = `${currentUrl}/page/${newPage}/`;
        }

        // Update the URL using router.push
        router.push(newUrl);
    };

    const getCreatedDate = (blogPage: iBlogPage) => {
        if (!blogPage.properties.showCreatedDate) {
            return "";
        }

        if (blogPage.properties.overrideCreatedDate && isValidDate(blogPage.properties.createdDate)) {
            return formatDateByCountry(blogPage.properties.createdDate, country);
        } else {
            return formatDateByCountry(blogPage.createDate, country);
        }
    }

    return (
        <div className="p-6 xl:p-0">
            <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
                {items.map((child: iBlogPage, index: number) => (
                    <BlogPostItem
                        key={index}
                        href={child.route.path}
                        title={child.properties.title}
                        image={child.properties.image ? GetAbsoluteUrlClient(child.properties.image[0].url) : defaultImage}
                        text={StripHtml(child.properties.preamble ?? child.properties.mainBody?.markup)}
                        postedDate={getCreatedDate(child)}
                    />
                ))}
            </div>
            {loading ? (
                <div className="flex justify-center mt-10">
                    <Loader loading={loading} useFullWindow={false} useNoBackground={true} />
                </div>
            ) : (
                <Pagination currentPage={currentPage} totalPages={totalPages} showPrevious={true} showNext={true} baseUrl={baseUrl} nextText={translations.general.next} previousText={translations.general.previous} />
            )}
        </div>
    );
};
