'use client';
import { logError } from '@/app/helpers/azureBlobLogger';
import { GetBlockWrapperClasses } from '@/app/helpers/clientUtilityHelper';
import { iIFrameBlock } from '@/app/models/blockTypes/iframeBlock';
import { iRequestContext } from '@/app/models/contextModels/requestContext';

interface Props {
    block: iIFrameBlock;
    requestContext: iRequestContext;
}


export default function IFrameBlock({ block, requestContext }: Props) {
    try {
        const iframeHeight = block.properties.iFrameHeight ? block.properties.iFrameHeight : '225px';

        return (
            <div style={{ height: iframeHeight }} className={`${GetBlockWrapperClasses(block)} iframe-block`}>
                <iframe
                    src={block.properties.iFrameAddress}
                    title={block.properties.iFrameTitle}
                    style={{ height: iframeHeight }}
                    loading='lazy'
                    height={iframeHeight}
                    data-reactroot=""
                    className="w-full" >
                    {requestContext.translations.blocks.iframeBlock.notSupported}
                </iframe>
            </div>
        );
    } catch (error) {
        logError("IFrameBlock.tsx", 'Error when generating IFrameBlock', `current url: ${requestContext.url}`, 'Error', error);
    }
}
