'use client';
import { iFAQGroupBlock } from '@/app/models/blockTypes/faqGroupBlock';
import { iRequestContext } from '@/app/models/contextModels/requestContext';
import FAQBlock from '../FAQBlock/FAQBlock';
import { iFAQBlock } from '@/app/models/blockTypes/faqBlock';
import { GetAbsoluteUrlClient, GetBlockWrapperClasses } from '@/app/helpers/clientUtilityHelper';
import { useEffect, useState } from 'react';
import { iGetContentListRequestModel } from '@/app/models/apiModels/requestModels/getContentListRequestModel';
import { clientLogError } from '@/app/helpers/clientLoggingHelper';
import Loader from '@/app/components/Loader/Loader';

interface Props {
    block: iFAQGroupBlock;
    requestContext: iRequestContext;
}


export default function FAQGroupClientBlock({ block, requestContext }: Props) {
    const [isLoading, setIsLoading] = useState(true);
    const [fetchedFaqBlocks, setFetchedFaqBlocks] = useState<iFAQBlock[]>();


    useEffect(() => {
        const fetchBlocks = async () => {
            setIsLoading(true);

            try {
                const safeBlockListItems = Array.isArray(block.properties.faqBlocks?.items) ? block.properties.faqBlocks?.items : [];
                const blockIdsList = safeBlockListItems
                    .filter((block) => block.content?.properties?.block != null)
                    .map((block) => block.content.properties.block.id);
                const requestModel: iGetContentListRequestModel = {
                    contentIds: blockIdsList ?? [],
                    language: requestContext.language,
                    previewMode: requestContext.previewMode,
                };

                const response = await fetch('/api/content/getContentList', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(requestModel),
                });

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                const data = await response.json();
                const tempFetchedBlocks = data as iFAQBlock[];
                setFetchedFaqBlocks(tempFetchedBlocks);
            }
            catch (error) {
                clientLogError("FAQGroupClientBlock.tsx", "fetchItems", error);

            }
            finally {
                setIsLoading(false);
            }
        }

        fetchBlocks();
    }, []);


    return (
        <div className="px-6 xl:px-0 w-full">
            <div className={`faq-group-block w-full ${GetBlockWrapperClasses(block)}`}>
                {block.properties.showTitle && (
                    <div className="mb-8  flex items-center">
                        {block.properties.icon && block.properties.icon[0] && (
                            <img
                                className='inline mr-4 max-w-[60px] max-h-[60px] object-contain'
                                src={GetAbsoluteUrlClient(block.properties.icon[0].url)}
                                alt={block.properties.title ? block.properties.title : ""} />
                        )}
                        {block.properties.title && (
                            <h2 className="flex-1 mb-0-i">
                                {block.properties.title}
                            </h2>
                        )}
                    </div>

                )}
                {isLoading ? (
                    <Loader loading={isLoading} useFullWindow={false} useNoBackground={true} />
                ) : (
                    <>
                        {fetchedFaqBlocks &&
                            <div className="grid grid-cols-1 gap-6">
                                {fetchedFaqBlocks.map((faqBlock: iFAQBlock, index: number) => (
                                    <FAQBlock key={index} block={faqBlock} translations={requestContext.translations} currentUrl={requestContext.url} isInGroup={true} />
                                ))}
                            </div>
                        }
                    </>
                )}

            </div>
        </div>
    );
}
