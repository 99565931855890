import { iTrustPilotBlock } from '@/app/models/blockTypes/trustPilotBlock';
import { iRequestContext } from '@/app/models/contextModels/requestContext';
import Script from 'next/script';

interface Props {
    block: iTrustPilotBlock;
    requestContext: iRequestContext;
}

export default function TrustPilotBlock({ block, requestContext }: Props) {
    const templateId = block.properties.trustPilotTemplateId ?? requestContext.configurations.defaultValues.blocks.trustPilotBlock.templateId;
    const businessUnitId = block.properties.trustPilotBusinessUnitId ?? requestContext.configurations.defaultValues.blocks.trustPilotBlock.businessUnitId;
    const url = block.properties.trustPilotURL ?? requestContext.configurations.defaultValues.blocks.trustPilotBlock.url;
    const height = block.properties.trustPilotHeight ?? requestContext.configurations.defaultValues.blocks.trustPilotBlock.height ?? "240px";
    const width = block.properties.trustPilotWidth ?? requestContext.configurations.defaultValues.blocks.trustPilotBlock.width ?? "100%";
    const starsToShow = block.properties.trustPilotStarsToShow ?? requestContext.configurations.defaultValues.blocks.trustPilotBlock.starsToShow ?? "4,5";
    const visibleReviewLanguage = block.properties.trustPilotVisibleReviewLanguage ?? requestContext.configurations.defaultValues.blocks.trustPilotBlock.visibleReviewLanguage ?? requestContext.language;

    return (
        <>
            <div className="trustpilot-widget" data-locale={requestContext.culture} data-template-id={templateId} data-businessunit-id={businessUnitId} data-style-height={height} data-style-width={width} data-stars={starsToShow} data-review-languages={visibleReviewLanguage}>
                <a href={url} target="_blank" rel="noopener">Trustpilot</a>
            </div>
            <Script
                id="trustpilot-script"
                strategy="afterInteractive"
                dangerouslySetInnerHTML={{
                    __html: `
                        (function() {
                            var scriptSrc = '//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js';
                            if (!document.querySelector('script[src="' + scriptSrc + '"]')) {
                                var script = document.createElement('script');
                                script.src = scriptSrc;
                                script.async = true;
                                script.type = 'text/javascript';
                                document.head.appendChild(script);
                            }
                        })();
                    `,
                }}
            />
        </>
    );
}
