'useClient';
import { iDictionary } from '@/app/models/dictionary';
import React, { useEffect, useState } from 'react';

interface FormCheckboxProps {
    name: string;
    label?: string;
    value?: string;
    errors?: iDictionary;
    isRequiredText?: string;
    className?: string;
    cssClasses?: string;
    isMandatory?: boolean;
    helperText?: string;
    disabled?: boolean;
    children?: React.ReactNode;
    isWhite?: boolean;
    checked?: boolean;
    isReversed?: boolean;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onError?: (name: string, error: string | string[]) => void;
    onValueChange?: (name: string, value: string | boolean, countryData?: any) => void;
}

const FormCheckbox: React.FC<FormCheckboxProps> = ({
    name,
    label,
    errors,
    isRequiredText,
    className = '',
    isMandatory = false,
    helperText = null,
    disabled = false,
    children,
    isWhite = false,
    onChange,
    checked = undefined,
    isReversed = false,
    value,
    onError,
    onValueChange
}) => {
    const checkboxClass = isWhite ? 'checkbox-white' : 'checkbox-default';
    const [showError, setShowError] = useState<boolean>(false);
    const [errorMessages, setErrorMessages] = useState<string[]>([]);

    useEffect(() => {
        if (errors && errors[name]) {
            setShowError(true);

            if (typeof errors[name] === 'string') {
                setErrorMessages([errors[name]]);
            } else {
                setErrorMessages(Object.values(errors[name]));
            }

            if (onError) {
                onError(name, errors[name]);
            }

        } else {
            setShowError(false);
        }
    }, [errors]);
    const inputProps = {
        id: name,
        name: name,
        'aria-label': label,
        disabled,
        // onChange,
        type: "checkbox",
        value,
        checked
    };

    return (
        <div className={`${className} ${checkboxClass} ${(!label && !children) ? "inline-block" : "py-1"} ${showError ? "validation-error" : ""}`}>
            {isReversed ? (
                <>
                    <label className='mb-0-i' htmlFor={name}>
                        <div className="checkbox-wrapper flex items-start">
                            {(label || children) && (
                                <div className="checkbox-content pl-2 w-full">
                                    {label ? (
                                        <span>{`${label}${isMandatory ? '*' : ''}`}</span>
                                    ) : (
                                        children
                                    )}
                                </div>
                            )}
                            <input
                                {...inputProps}
                                onChange={(e) => {
                                    if (onValueChange) {
                                        onValueChange(name, e.target.checked);
                                    }
                                    if (onChange) {
                                        onChange(e);
                                    }
                                }}
                            />
                            <span aria-hidden="true" className="custom-checkbox mb-0"></span>
                        </div>
                    </label>
                    {helperText && <span className="helper-text">{helperText}</span>}
                    {errors && errors[name] && (
                        <span role="alert">{isRequiredText}</span>
                    )}
                </>
            ) : (
                <>
                    <label className='mb-0-i' htmlFor={name}>
                        <div className="checkbox-wrapper flex items-start">
                            <input
                                {...inputProps}
                                onChange={(e) => {
                                    if (onValueChange) {
                                        onValueChange(name, e.target.checked);
                                    }
                                    if (onChange) {
                                        onChange(e);
                                    }
                                }}
                            />
                            <span aria-hidden="true" className="custom-checkbox mb-0"></span>
                            {(label || children) && (

                                <div className="checkbox-content pl-2 w-full">
                                    {label ? (
                                        <span>{`${label}${isMandatory ? '*' : ''}`}</span>
                                    ) : (
                                        children
                                    )}
                                </div>
                            )}
                        </div>
                    </label>
                    {helperText && <span className="helper-text">{helperText}</span>}
                    {showError && (
                        <span role="alert">{`${errorMessages.join(", ")}`}</span>
                    )}
                </>
            )}

        </div>
    );
};

export default FormCheckbox;