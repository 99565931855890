"use client";
import { iOrderStoragePage } from "@/app/models/pageTypes/orderStorageStepPage";
import { doUseOrderStorageStore } from "@/stores/order-storage-store";
import useStore from "@/stores/use-store";
import { useRouter } from "next/navigation";
import { FormEvent, use, useEffect, useLayoutEffect, useState } from "react";
import { useShallow } from "zustand/react/shallow";
import Loader from "@/app/components/Loader/Loader";
import { CaptureResponse } from "@/app/models/apiModels/captureResponse";
import { PaymentPaidResponse } from "@/app/models/apiModels/paymentPaidResponse";
import { CancelResponse } from "@/app/models/apiModels/cancelResponse";
import React from "react";
import { clientLogError, clientLogInfo } from "@/app/helpers/clientLoggingHelper";
import { PaymentorderResponseRoot } from "@/app/models/apiModels/startPaymentResponse";
import { ISpaceManagerUser } from '@/app/models/apiModels/spaceManagerUsersByIdentityNumberResponse';
import Modal from "@/app/components/Modal/Modal";
import LinkElement from "@/app/components/General/LinkElement";
import { eButtonColorTheme } from "@/app/models/enum/eButtonColorTheme";
import { CalculateAge, HashString } from "@/app/helpers/clientUtilityHelper";

interface OrderStoragePageProps {
  pageData: iOrderStoragePage;
}

interface LoginResponse {
  data: {
    errors: [];
    id: string;
    redirect_url: string;
    status: string;
  };
}

interface MyUserInfoModel {
  iss: string;
  iat: number;
  nbf: number;
  exp: number;
  identity: {
    cprNumberMatch: boolean;
    hasCpr: boolean;
    countryCode: string;
    firstName: string;
    fullName: string;
    personalNumber: string;
    dateOfBirth: string;
    age: number;
    idProviderName: string;
    identificationDate: string;
    idProviderRequestId: string;
    idProviderPersonId: string;
  };
  providerData: {
    "dk.mitid.assurancelevel": {
      loa: string;
      ial: string;
      aal: string;
      fal: string;
    };
    sub: string;
    "dk.mitid.psd2": boolean;
    amr: string[];
    kid: string;
    iss: string;
    "dk.mitid.hasCPR": boolean;
    exp: number;
    iat: number;
    jti: string;
    "dk.mitid.transactionid": string;
    aud: string;
    nbf: number;
    "dk.mitid.attributes": {
      "mitid.dk.date_of_birth": string;
      "mitid.dk.identity_name": string;
      "mitid.dk.ial_identity_assurance_level": string;
      "mitid.dk.age": string;
    };
  };
}

export default function OrderStorageStep3Register({
  pageData,
}: OrderStoragePageProps) {
  let translation = pageData.requestContext.translations;
  const orderFlowUrl = pageData.requestContext.configurations.orderingFlow;
  const url = orderFlowUrl.step3Page.url.substring(
    0,
    orderFlowUrl.step3Page.url.length - 1
  );
  const [loading, setLoading] = useState<boolean>(true);
  const [showError, setShowError] = useState<boolean>(false);
  const [errorTitle, setErrorTitle] = useState<string>("");
  const [errorText, setErrorText] = useState<string>("");
  const [reroutingHref, setReroutingHref] = useState<string>("");
  const [requestSent, setRequestSent] = useState<boolean>(false);
  const [doUnreserve, setDoUnreserve] = useState<boolean>(false);
  const [paidResponse, setPaidResponse] = useState<PaymentPaidResponse | null>(null);

  const router = useRouter();
  const [mydata, setMyData] = useState<LoginResponse | null>(null);
  const [myuserInfodata, setMyUserInfoData] = useState<MyUserInfoModel | null>(null);


  const biglocalstorage = doUseOrderStorageStore(useShallow((state) => state));
  const trackingStore = doUseOrderStorageStore(useShallow((state) => state.tracking));

  const sessionstatusinit = pageData.searchParams.sessionStatus as string;
  const [sessionStatus, setSessionStatus] = useState(sessionstatusinit);
  const [authOk, setAuth] = useState(false);
  const [mitidGo, setMitidGo] = useState(false);
  const [readyToStartPayment, setReayToStartPayment] = useState(false);
  const [readyToReversePayment, setReayToReversePayment] = useState(false);
  const [checkIfPaid, setCheckIfPaid] = useState(false);
  const [readyToCapture, setreadyToCapture] = useState(false);
  const [readyToRent, setReadyToRent] = useState(false);
  const [readyToRentPaid, setReadyToRentPaid] = useState(false);
  const [readyToCreate, setReadyToCreate] = useState(false);
  const [createChecked, setCreateChecked] = useState(false);
  const sessionId = pageData.searchParams.sessionId as string ?? pageData.cookiesSessionId;
  const isUnitId = pageData.searchParams.unitId as string;
  const [redirectCheckoutHref, setRedirectCheckoutHref] = useState("");
  const [mitIdSession, setMitIdSession] = useState<iMitIdSessionResponse | undefined>(undefined);

  const setValue = useStore(
    doUseOrderStorageStore,
    useShallow((state) => state)
  )?.setPaymentValue;

  const setUserValue = useStore(
    doUseOrderStorageStore,
    useShallow((state) => state)
  )?.setUserValue;

  const setAllUserValues = useStore(
    doUseOrderStorageStore,
    useShallow((state) => state)
  )?.setAllUserValues;

  const setSessionValue = useStore(
    doUseOrderStorageStore,
    useShallow((state) => state)
  )?.setSessionValue;

  const setTrackingValue = useStore(
    doUseOrderStorageStore,
    useShallow((state) => state)
  )?.setTrackingValue;

  const resetRecruitAFriendStore = useStore(
    doUseOrderStorageStore,
    useShallow((state) => state)
  )?.resetRecruitAFriend;


  useEffect(() => {
    if (!sessionStatus || (sessionStatus.toLowerCase() !== "finished" && sessionStatus.toLowerCase() !== "failed" && sessionStatus.toLowerCase() !== "completed")) {
      try {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: "booking_step",
          step: "sign_and_pay",
          site_code: trackingStore.siteCode,
          site_id: trackingStore.siteId,
          site_name: trackingStore.siteName,
          size: trackingStore.size,
          booking_id: trackingStore.bookingId,
          currency: trackingStore.currency,
          storage_value: trackingStore.storageValue,
          first_month_value: trackingStore.firstMonthValue,
          ordinary_month_value: trackingStore.ordinaryMonthValue,
          client_type: trackingStore.clientType,
          insurance: trackingStore.insurance,
        });
      } catch (error) {
        clientLogError("OrderStorageStep3RegisterPage", "Tracing", "Could not trace Order Step 3", error);
      }
    }
  }, []);

  function isValidCPR(cpr: string): boolean {
    if (cpr.length !== 10) return false;

    const day = parseInt(cpr.substring(0, 2), 10);
    const month = parseInt(cpr.substring(2, 4), 10);
    const year = parseInt(cpr.substring(4, 6), 10);

    const currentYear = new Date().getFullYear();
    const fullYear = year + (year <= currentYear % 100 ? 2000 : 1900);

    const birthDate = new Date(fullYear, month - 1, day);

    var age = new Date().getFullYear() - birthDate.getFullYear();
    const monthDiff = new Date().getMonth() - birthDate.getMonth();
    const dayDiff = new Date().getDate() - birthDate.getDate();

    if (monthDiff < 0 || (monthDiff === 0 && dayDiff < 0)) {
      age--;
    }

    return age >= 18;
  }

  const GetSessionInfo = async (sessionId: string): Promise<iMitIdSessionResponse | undefined> => {
    const timespan = new Date().getTime();
    const sessionresponse = await fetch(`/api/login/sessions`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        sessionId: sessionId,
        country: pageData.requestContext.country,
      },
    });

    if (!sessionresponse.ok) {
      clientLogError("OrderStorageStep3RegisterPage.tsx", "GetSessionInfo", "Session response not ok");
      initError(translation.pages.order.paymentContainer.mitidNotOK, translation.pages.order.paymentContainer.mitidNotOKText);
      return;
    }

    const sessionResult = await sessionresponse.json();
    if (sessionResult) {
      const mitidUserData: iMitIdSessionResponse = sessionResult as iMitIdSessionResponse;
      setUserValue?.("forename", (mitidUserData.firstName));
      setUserValue?.("surname", (mitidUserData.fullName));

      if (mitidUserData.dateOfBirth) {
        setUserValue?.("dateOfBirth", mitidUserData.dateOfBirth);
      }

      if (mitidUserData.loginToken) {
        setUserValue?.("loginToken", mitidUserData.loginToken);
      }

      return mitidUserData;
    }

    return;
  };

  const GetSpaceManagerUsersByToken = async (loginToken: string) => {
    const useresponse = await fetch("/api/user/getSpaceManagerUsersByToken", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        loginToken: loginToken,
        filter: "false",
        companyNumber: "",
        Country: pageData.requestContext.country,
      },
    });

    if (!useresponse.ok) {
      clientLogError("OrderStorageStep3RegisterPage.tsx", "GetSpaceManagerUsersByToken", "user response not ok");
      initError(translation.pages.order.paymentContainer.spaceProblem, translation.pages.order.paymentContainer.spaceProblemText);
      return;
    }
    return await useresponse.json() as ISpaceManagerUser[];
  };

  // Start paymnet
  const startPayment = async () => {
    let redirectCheckoutOperation: string;

    try {
      setCreateChecked(false);
      const createresponse = await createuser(biglocalstorage.user.loginToken);

      const formData = {
        hostName: "https://" + window.location.host + "/" + pageData.requestContext.country,
        payeeReference: `BUY00${Math.floor((Math.random() * (12345678910 - 1234567891 + 1)) + 1234567891)
          .toString()
          .replace(/[-:.TZ]/g, "")
          .slice(2, 16)}`,
        purchaseInformation: translation.pages.order.purchaseInformation,
        siteCode: biglocalstorage.payment.siteCode,
        userAgent: navigator.userAgent,
        country: pageData.requestContext.country,

        ...paymentInfo(),
      };

      const response = await fetch("/api/pay/startPayment", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Country: pageData.requestContext.country,
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        clientLogError("OrderStorageStep3RegisterPage.tsx", "startPayment", "response not ok");
        initError(translation.pages.order.paymentContainer.startpayNotOK, translation.pages.order.paymentContainer.startpayNotOKText);
        return;
      }

      const paymentOrder: PaymentorderResponseRoot = await response.json();

      if (paymentOrder === null || paymentOrder === undefined) {
        clientLogError("OrderStorageStep3RegisterPage.tsx", "startPayment", "response not ok");
        initError(translation.pages.order.paymentContainer.startpayNotOK, translation.pages.order.paymentContainer.startpayNotOKText);
        return;
      }

      setValue?.("paymentOrderId", paymentOrder.paymentorder.id);
      const redirectCheckoutOperation = paymentOrder.operations.find(
        (operation) => operation.rel === 'redirect-checkout'
      );

      if (redirectCheckoutOperation) {
        setRedirectCheckoutHref(redirectCheckoutOperation.href);

      } else {
        clientLogError("OrderStorageStep3RegisterPage.tsx", "startPayment", "No redirectCheckoutOperation found");
        initError(translation.pages.order.paymentContainer.startpayNotOK, translation.pages.order.paymentContainer.startpayNotOKText);
        return;
      }
    } catch (error) {
      clientLogError("OrderStorageStep3RegisterPage.tsx", "startPayment", "No redirectCheckoutOperation found", error);
      initError(translation.pages.order.paymentContainer.startpayNotOK, translation.pages.order.paymentContainer.startpayNotOKText);
      return;
    } finally {
    }
  };

  // Reverse paymnet
  const reversePayment = async () => {
    let redirectCheckoutOperation: string;

    try {
      const formData = {
        paymentOrderId: biglocalstorage.payment.paymentOrderId,
        country: pageData.requestContext.country,

        ...paymentInfo(),
      };

      const response = await fetch("/api/pay/reversePayment", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Country: pageData.requestContext.country,
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        clientLogError("OrderStorageStep3RegisterPage.tsx", "reversePayment", "response not ok");
        initError(translation.pages.order.paymentContainer.capOKrevNotOK, translation.pages.order.paymentContainer.capOKrevNotOKText);
        return;
      }

      //: ReversalResponseRoot
      const paymentReverseOrder = await response.json();
      if (paymentReverseOrder !== null && paymentReverseOrder.reversal.transaction.state === 'Completed') {
        initError(translation.pages.order.paymentContainer.capOKrevOK, translation.pages.order.paymentContainer.capOKrevOKText);
      } else {
        clientLogError("OrderStorageStep3RegisterPage.tsx", "reversePayment", "reversepayment failed", biglocalstorage.payment.paymentOrderId);
        initError(translation.pages.order.paymentContainer.capOKrevNotOK, translation.pages.order.paymentContainer.capOKrevNotOKText);
        return;
      }
    } catch (error) {
      clientLogError("OrderStorageStep3RegisterPage.tsx", "reversePayment", "reversepayment catch", error);
      initError(translation.pages.order.paymentContainer.capOKrevNotOK, translation.pages.order.paymentContainer.capOKrevNotOKText)
    } finally {
    }
  };

  // Check status of the payment
  const paymentPaidSubmit = async (paymentIdUrl: string) => {
    if (!paymentIdUrl || paymentIdUrl === "") {
      clientLogError("OrderStorageStep3RegisterPage.tsx", "paymentPaidSubmit", "empty paymentIdUrl");
      return;
    }
    try {
      const response = await fetch("/api/pay/paidPaymentByIdUrl", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Country: pageData.requestContext.country,
          PaymentIdUrl: paymentIdUrl,
        },
      });

      if (!response.ok) {
        clientLogError("OrderStorageStep3RegisterPage.tsx", "paymentPaidSubmit", "response not ok");
        initError(translation.pages.order.paymentContainer.paidNotOK, translation.pages.order.paymentContainer.paidNotOKText);
        return;
      }
      const data: PaymentPaidResponse = await response.json();

      if (data === null || !data.paid) {
        initError(translation.pages.order.paymentContainer.paidNotOK, translation.pages.order.paymentContainer.paidNotOKText);
        return;
      }

      setPaidResponse(data);

    } catch (error) {
      clientLogError("OrderStorageStep3RegisterPage.tsx", "paymentPaidSubmit", "catch", error);
      initError(translation.pages.order.paymentContainer.paidNotOK, translation.pages.order.paymentContainer.paidNotOKText);
    } finally {

    }
  };

  // Capture payment and rent unit
  const oncaptureSubmit = async () => {
    let redirectCheckoutOperation: string;

    if (paidResponse) {
      try {
        const formData = {
          id: paidResponse.paid.id.slice(0, -5),
          payeeReference: `CAP00${Math.floor((Math.random() * (12345678910 - 1234567891 + 1)) + 1234567891)
            .toString()
            .replace(/[-:.TZ]/g, "")
            .slice(2, 16)}`,
          purchaseInformation: translation.pages.order.purchaseInformation,

          ...paymentInfo(),
        };

        const response = await fetch("/api/pay/capturePayment", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Country: pageData.requestContext.country,
          },
          body: JSON.stringify(formData),
        });

        if (!response.ok) {
          clientLogError("OrderStorageStep3RegisterPage.tsx", "oncaptureSubmit", "response not ok");
          await oncancelSubmit();
          initError(translation.pages.order.paymentContainer.capNotOK, translation.pages.order.paymentContainer.capNotOKText);
          return;
        }

        const capture: CaptureResponse = await response.json();

        if (capture !== null && capture.capture.transaction.state === 'Completed') {
          setReadyToRentPaid(true);

        } else {
          clientLogError("OrderStorageStep3RegisterPage.tsx", "oncaptureSubmit", "state != Completed");
          await oncancelSubmit();
          initError(translation.pages.order.paymentContainer.capNotOK, translation.pages.order.paymentContainer.capNotOKText);
          return;
        }

      } catch (error) {
        clientLogError("OrderStorageStep3RegisterPage.tsx", "oncaptureSubmit", "catch", error);
        await oncancelSubmit();
        initError(translation.pages.order.paymentContainer.capNotOK, translation.pages.order.paymentContainer.capNotOKText);
        return;
      } finally {
      }
    }
    else {
      clientLogError("OrderStorageStep3RegisterPage.tsx", "oncaptureSubmit", "No paid response");
    }
  };

  const rentUnit = async (paymentPaidResponse: PaymentPaidResponse | null) => {
    let rentdata = {};
    let customerID;
    const createresponse = await createuser(biglocalstorage.user.loginToken);
    if (biglocalstorage.user.loginToken !== "") {
      const users = await GetSpaceManagerUsersByToken(biglocalstorage.user.loginToken) ?? [];

      if (users && users.length > 0) {

        if (biglocalstorage.user.isCompany) {
          customerID = users.find(x => x.orgNumber === biglocalstorage.user.companyOrgNumber && x.company === "1")?.customerID;
          setUserValue?.("customerId", customerID);
        }
        if (!biglocalstorage.user.isCompany) {
          customerID = users.find(x => x.loginToken === biglocalstorage.user.loginToken && x.company === "0")?.customerID;
          setUserValue?.("customerId", customerID);
        }
      }
    }

    // let name = await GetSessionInfo(biglocalstorage.session.sessionId);
    const recruitReferenceId = biglocalstorage.recruitAFriend?.referenceId ?? "";

    if (biglocalstorage.user.isInvoice) {

      rentdata = {
        loginToken: biglocalstorage.user.loginToken,
        userName: customerID ?? biglocalstorage.user.customerId,
        firstName: mitIdSession?.firstName,
        lastName: mitIdSession?.surName,
        dateOfBirth: biglocalstorage.user.dateOfBirth,
        rentedUnitNumber: biglocalstorage.unit.unitId,
        rentedUnitFrom: "",
        address: biglocalstorage.user.streetAddress,
        bookingID: "", //TODO: Add same as tracking?
        cardExpire: "",
        cardNumber: "",
        city: biglocalstorage.user.city,
        companyName: biglocalstorage.user.companyName ?? "",
        companyNumber: biglocalstorage.user.companyOrgNumber ?? "",
        email: biglocalstorage.user.email,
        selectedInsurance: biglocalstorage.insurance.insuranceLedgerId,
        invoiceEmail: biglocalstorage.user.invoiceEmail,
        invoiceMarkup: biglocalstorage.user.invoiceReference,
        isInvoice: biglocalstorage.user.isInvoice,
        eSigningOcspResponse: "",
        mobile: biglocalstorage.user.phoneNumber,
        recurrenceToken: "",
        unscheduledToken: "",
        refId: recruitReferenceId,
        eSigningSignature: "",
        siteId: biglocalstorage.payment.siteId,
        sizeCodeId: biglocalstorage.payment.sizeCodeId,
        selectedStartDate: biglocalstorage.date.startDate,
        zipCode: biglocalstorage.user.postalCode,

        ...paymentInfo(),
      };
    }
    else {
      if (paymentPaidResponse) {
        if (biglocalstorage.user.isCompany === true) {
          let tokenCardData = paymentPaidResponse?.paid.tokens.find((token) => token.type === "unscheduled" || token.type === "recurrence");

          rentdata = {
            loginToken: biglocalstorage.user.loginToken,
            userName: customerID ?? biglocalstorage.user.customerId,
            firstName: mitIdSession?.firstName,
            lastName: mitIdSession?.surName,
            rentedUnitNumber: biglocalstorage.unit.unitId,
            rentedUnitFrom: "",
            address: biglocalstorage.user.streetAddress,
            bookingID: "",//TODO: Add same as tracking?
            cardExpire: tokenCardData?.expiryDate,
            cardNumber: tokenCardData?.name,
            city: biglocalstorage.user.city,
            companyName: biglocalstorage.user.companyName ?? "",
            companyNumber: biglocalstorage.user.companyOrgNumber ?? "",
            email: biglocalstorage.user.email,
            selectedInsurance: biglocalstorage.insurance.insuranceLedgerId,
            invoiceEmail: "",
            invoiceMarkup: "",
            isInvoice: biglocalstorage.user.isInvoice,
            eSigningOcspResponse: "",
            mobile: biglocalstorage.user.phoneNumber,
            recurrenceToken: paymentPaidResponse.paid.tokens?.find(token => token.type === "recurrence")?.token ?? "",
            unscheduledToken: paymentPaidResponse.paid.tokens?.find(token => token.type === "unscheduled")?.token ?? "",
            refId: recruitReferenceId,
            eSigningSignature: "",
            siteId: biglocalstorage.payment.siteId,
            sizeCodeId: biglocalstorage.payment.sizeCodeId,
            selectedStartDate: biglocalstorage.date.startDate,
            zipCode: biglocalstorage.user.postalCode,

            ...paymentInfo(),
          };
        }

        if (biglocalstorage.user.isCompany === false) {
          let tokenCardData = paymentPaidResponse?.paid.tokens.find((token) => token.type === "unscheduled" || token.type === "recurrence");

          rentdata = {
            loginToken: biglocalstorage.user.loginToken,
            userName: customerID ?? biglocalstorage.user.customerId,
            firstName: mitIdSession?.firstName,
            lastName: mitIdSession?.surName,
            dateOfBirth: biglocalstorage.user.dateOfBirth,
            rentedUnitNumber: biglocalstorage.unit.unitId,
            rentedUnitFrom: "",
            address: biglocalstorage.user.streetAddress,
            bookingID: "",//TODO: Add same as tracking?
            cardExpire: tokenCardData?.expiryDate,
            cardNumber: tokenCardData?.name,
            city: biglocalstorage.user.city,
            companyName: "",
            companyNumber: "",
            email: biglocalstorage.user.email,
            selectedInsurance: biglocalstorage.insurance.insuranceLedgerId,
            invoiceEmail: "",
            invoiceMarkup: "",
            isInvoice: biglocalstorage.user.isInvoice,
            eSigningOcspResponse: "",
            mobile: biglocalstorage.user.phoneNumber,
            recurrenceToken: paymentPaidResponse.paid.tokens?.find(token => token.type === "recurrence")?.token ?? "",
            unscheduledToken: paymentPaidResponse.paid.tokens?.find(token => token.type === "unscheduled")?.token ?? "",
            refId: recruitReferenceId,
            eSigningSignature: "",
            siteId: biglocalstorage.payment.siteId,
            sizeCodeId: biglocalstorage.payment.sizeCodeId,
            selectedStartDate: biglocalstorage.date.startDate,
            zipCode: biglocalstorage.user.postalCode,

            ...paymentInfo(),
          };
        }
      }
    }

    const rentresponse = await fetch("/api/checkout/rentUnit", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Country: pageData.requestContext.country,
      },
      body: JSON.stringify(rentdata),
    });

    if (!rentresponse.ok) {
      clientLogError("OrderStorageStep3RegisterPage.tsx", "oncaptureSubmit", "rentresponse not ok", rentdata);
      reversePayment();
      return;
    }

    const rentresponseResult = await rentresponse.json();

    if (rentresponseResult === null || rentresponseResult.response === null) {
      clientLogError("OrderStorageStep3RegisterPage.tsx", "oncaptureSubmit", "rentresponse not ok", rentdata);
      reversePayment();
      return;
    }

    if (rentresponse.ok) {
      if (rentresponseResult.response === null) {
        if (rentresponseResult.response === null && !biglocalstorage.user.isInvoice) {
          clientLogError("OrderStorageStep3RegisterPage.tsx", "oncaptureSubmit", "renting = null", rentdata);
          reversePayment();
          return;
        }
        if (rentresponseResult.response === null && biglocalstorage.user.isInvoice) {
          clientLogError("OrderStorageStep3RegisterPage.tsx", "oncaptureSubmit", "renting = null invoice", rentdata);
          initError(translation.pages.order.paymentContainer.rentNotOK, translation.pages.order.paymentContainer.rentNotOKText);
          return;
        }
      }
      else {
        try {
          const paymentMethod = paymentPaidResponse?.paid.instrument;
          const hashedEmail = await HashString(biglocalstorage.user.email);

          if (setTrackingValue) {
            setTrackingValue("paymentMethod", paymentMethod);
            setTrackingValue("hashedEmail", hashedEmail);
          }

          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
            event: "booking_step",
            step: "payment_complete",
            site_code: trackingStore.siteCode,
            site_id: trackingStore.siteId,
            site_name: trackingStore.siteName,
            size: trackingStore.size,
            booking_id: trackingStore.bookingId,
            currency: trackingStore.currency,
            storage_value: trackingStore.storageValue,
            first_month_value: trackingStore.firstMonthValue,
            ordinary_month_value: trackingStore.ordinaryMonthValue,
            client_type: trackingStore.clientType,
            insurance: trackingStore.insurance,
            payment_method: paymentMethod,
            hashed_email: hashedEmail
          });
        } catch (error) {
          clientLogError("OrderStorageStep3RegisterPage", "Tracing", "Could not trace Order Step 3 - after payment", error);
        }

        if (resetRecruitAFriendStore) {
          resetRecruitAFriendStore();
        }

        router.push(pageData.requestContext.configurations.orderingFlow.step4Page.url);
      }

    }
  };

  //cancel payment
  const oncancelSubmit = async () => {
    let redirectCheckoutOperation: string;

    if (paidResponse) {
      try {
        const response = await fetch("/api/pay/cancelPayment", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Country: pageData.requestContext.country,
            spaceManagerInvoiceInvNum: biglocalstorage.unit.unitId,
            cancelurl: biglocalstorage.payment.paymentOrderId,
          },
        });

        if (!response.ok) {
          clientLogError("OrderStorageStep3RegisterPage.tsx", "oncancelSubmit", "response not ok", biglocalstorage.unit.unitId, biglocalstorage.payment.paymentOrderId);
          initError(translation.pages.order.paymentContainer.capOKcanNotOK, translation.pages.order.paymentContainer.capOKcanNotOKText);
          return;
        }

        const cancel: CancelResponse = await response.json();

        if (cancel !== null && cancel.cancellation.transaction.state === 'Completed') {
          initError(translation.pages.order.paymentContainer.capOKcanOK, translation.pages.order.paymentContainer.capOKcanOKText);
          return;
        } else {
          clientLogError("OrderStorageStep3RegisterPage.tsx", "oncancelSubmit", "state != Completed", biglocalstorage.unit.unitId, biglocalstorage.payment.paymentOrderId);
          initError(translation.pages.order.paymentContainer.capOKcanNotOK, translation.pages.order.paymentContainer.capOKcanNotOKText);
          return;
        }


      } catch (error) {
        clientLogError("OrderStorageStep3RegisterPage.tsx", "oncancelSubmit", "catch", error);
        initError(translation.pages.order.paymentContainer.capOKcanNotOK, translation.pages.order.paymentContainer.capOKcanNotOKText);
      } finally {
      }
    }
    else {
      clientLogError("OrderStorageStep3RegisterPage.tsx", "oncancelSubmit", "No paid response");
    }
  };

  //Go to mitid  
  async function loginToMitid() {
    setRequestSent(false);

    let fullUrl = pageData.requestContext.configurations.orderingFlow.step3Page.url;

    if (fullUrl.includes("http://") || fullUrl.includes("https://")) {
      const urlObj = new URL(fullUrl);
      fullUrl = urlObj.pathname + urlObj.search + urlObj.hash;
    }


    const timespan = new Date().getTime();
    const response = await fetch(`/api/login/mitidauto?timespan=${timespan}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Country: pageData.requestContext.country,
      },
      body: JSON.stringify({
        url: fullUrl,
        mitidText: translation.pages.order.paymentContainer.mitidTextBody,
        language: pageData.requestContext.language,
      }),
    });


    if (!response.ok) {
      clientLogError("OrderStorageStep3RegisterPage.tsx", "loginToMitid", "response not ok");
      initError(translation.pages.order.paymentContainer.mitidNotOK, translation.pages.order.paymentContainer.mitidNotOKText);
      return;
    }

    const data = await response.json();
    setRequestSent(true);
    setMyData(data);

  }

  //send to login zignsec
  useEffect(() => {
    if (mydata?.data.redirect_url && requestSent === true) {
      router.push(mydata.data.redirect_url);
    }
  }, [mydata]);

  const createuser = async (loginToken: string) => {
    if (loginToken === "") {
      clientLogError("OrderStorageStep3RegisterPage.tsx", "createuser", "empty loginToken");
      initError(translation.pages.order.paymentContainer.createNotOK, translation.pages.order.paymentContainer.createNotOKText);
      return;
    }

    let fallbackMitIdSession: iMitIdSessionResponse | undefined = undefined;

    if (!mitIdSession) {
      fallbackMitIdSession = await checkLoginStatus();
    }

    const data = await GetSpaceManagerUsersByToken(loginToken);

    let newPerson = false;
    let newOrg = false;

    if (data && data.length > 0) {

      const foundCustomerid = data.find(x => x.loginToken === biglocalstorage.user.loginToken)?.customerID ?? "";
      setUserValue?.("customerId", foundCustomerid);
      if (biglocalstorage.user.isCompany === true && biglocalstorage.user.companyOrgNumber !== data.find(x => x.orgNumber === biglocalstorage.user.companyOrgNumber && x.company === "1")?.orgNumber) {
        newOrg = true;
      }

      if (biglocalstorage.user.isCompany === false && biglocalstorage.user.loginToken !== data.find(x => x.loginToken === biglocalstorage.user.loginToken && x.company === "0")?.loginToken) {
        newPerson = true;
      }

      if (newPerson || newOrg) {
        let createuserbody;

        if (newPerson) {
          createuserbody = {
            loginToken: biglocalstorage.user.loginToken,
            siteID: biglocalstorage.payment.siteId,
            surname: mitIdSession?.surName || fallbackMitIdSession?.surName,
            forename: mitIdSession?.firstName || fallbackMitIdSession?.firstName,
            dateOfBirth: mitIdSession?.dateOfBirth || fallbackMitIdSession?.dateOfBirth || biglocalstorage.user.dateOfBirth,
            email: biglocalstorage.user.email,
            phone: biglocalstorage.user.phoneNumber,
            company: "false",
            streetAddress: biglocalstorage.user.streetAddress,
            zipCode: biglocalstorage.user.postalCode,
            city: biglocalstorage.user.city,
            companyName: "",
            orgNumber: "",
          };
        }

        if (newOrg) {
          createuserbody = {
            loginToken: biglocalstorage.user.loginToken,
            siteID: biglocalstorage.payment.siteId,
            surname: mitIdSession?.surName || fallbackMitIdSession?.surName,
            forename: mitIdSession?.firstName || fallbackMitIdSession?.firstName,
            dateOfBirth: mitIdSession?.dateOfBirth || fallbackMitIdSession?.dateOfBirth || biglocalstorage.user.dateOfBirth,
            email: biglocalstorage.user.email,
            phone: biglocalstorage.user.phoneNumber,
            company: "true",
            streetAddress: biglocalstorage.user.streetAddress,
            zipCode: biglocalstorage.user.postalCode,
            city: biglocalstorage.user.city,
            companyName: biglocalstorage.user.companyName,
            orgNumber: biglocalstorage.user.companyOrgNumber,
          };
        }

        const createresponse = await fetch("/api/user/createUser", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Country: pageData.requestContext.country,
          },
          body: JSON.stringify(createuserbody),
        });
        if (!createresponse.ok) {
          clientLogError("OrderStorageStep3RegisterPage.tsx", "createuser", "createresponse not ok", createuserbody);
          initError(translation.pages.order.paymentContainer.createNotOK, translation.pages.order.paymentContainer.createNotOKText);
          return;
        }

        const createuser = await createresponse.json();

        if (!createuser) {
          clientLogError("OrderStorageStep3RegisterPage.tsx", "createuser", "createresponse not ok", createuserbody);
          initError(translation.pages.order.paymentContainer.createNotOK, translation.pages.order.paymentContainer.createNotOKText);
          return;
        }

        if (createuser.customerID && createuser.customerID !== null) {
          if (setAllUserValues) {
            setAllUserValues({
              user: {
                loginToken: biglocalstorage.user.loginToken,
                isCompany: biglocalstorage.user.isCompany,
                isInvoice: biglocalstorage.user.isInvoice,
                dateOfBirth: mitIdSession?.dateOfBirth || fallbackMitIdSession?.dateOfBirth || biglocalstorage.user.dateOfBirth,
                forename: mitIdSession?.firstName as string || fallbackMitIdSession?.firstName as string,
                surname: mitIdSession?.surName as string || fallbackMitIdSession?.surName as string,
                invoiceEmail: biglocalstorage.user.invoiceEmail,
                invoiceReference: biglocalstorage.user.invoiceReference,
                personalNumber: biglocalstorage.user.personalNumber,
                streetAddress: biglocalstorage.user.streetAddress,
                postalCode: biglocalstorage.user.postalCode,
                city: biglocalstorage.user.city,
                email: biglocalstorage.user.email,
                phoneNumber: biglocalstorage.user.phoneNumber,
                customerId: createuser.customerID,
                companyName: biglocalstorage.user.companyName,
                companyOrgNumber: biglocalstorage.user.companyOrgNumber,
              },
            });
          }
        }
      }
    }

    if (data && data.length === 0) {

      // let name = await GetSessionInfo(sessionId);

      if (!biglocalstorage.user.isCompany) {
        const createuserbody = {
          loginToken: biglocalstorage.user.loginToken,
          siteID: biglocalstorage.payment.siteId,
          surname: mitIdSession?.surName,
          forename: mitIdSession?.firstName,
          dateOfBirth: biglocalstorage.user.dateOfBirth,
          email: biglocalstorage.user.email,
          phone: biglocalstorage.user.phoneNumber,
          company: "false",
          streetAddress: biglocalstorage.user.streetAddress,
          zipCode: biglocalstorage.user.postalCode,
          city: biglocalstorage.user.city,
          companyName: "",
          orgNumber: "",
        };

        const createresponse = await fetch("/api/user/createUser", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Country: pageData.requestContext.country,
          },
          body: JSON.stringify(createuserbody),
        });

        if (!createresponse.ok) {
          clientLogError("OrderStorageStep3RegisterPage.tsx", "createuser", "createresponse not ok", createuserbody);
          initError(translation.pages.order.paymentContainer.createNotOK, translation.pages.order.paymentContainer.createNotOKText);
          return;
        }

        const createuser = await createresponse.json();

        if (createuser.customerID && createuser.customerID !== null) {
          if (setAllUserValues) {
            setAllUserValues({
              user: {
                loginToken: biglocalstorage.user.loginToken,
                isCompany: false,
                isInvoice: biglocalstorage.user.isInvoice,
                forename: mitIdSession?.firstName as string,
                surname: mitIdSession?.surName as string,
                dateOfBirth: biglocalstorage.user.dateOfBirth,
                invoiceEmail: biglocalstorage.user.invoiceEmail,
                invoiceReference: biglocalstorage.user.invoiceReference,
                personalNumber: biglocalstorage.user.personalNumber,
                streetAddress: biglocalstorage.user.streetAddress,
                postalCode: biglocalstorage.user.postalCode,
                city: biglocalstorage.user.city,
                email: biglocalstorage.user.email,
                phoneNumber: biglocalstorage.user.phoneNumber,
                customerId: createuser.customerID,
                companyName: "",
                companyOrgNumber: "",
              },
            });
          }
        }
      }

      if (biglocalstorage.user.isCompany) {
        const createuserbody = {
          loginToken: biglocalstorage.user.loginToken,
          siteID: biglocalstorage.payment.siteId,
          surname: mitIdSession?.surName || fallbackMitIdSession?.surName,
          forename: mitIdSession?.firstName || fallbackMitIdSession?.firstName,
          dateOfBirth: mitIdSession?.dateOfBirth || fallbackMitIdSession?.dateOfBirth || biglocalstorage.user.dateOfBirth,
          email: biglocalstorage.user.email,
          phone: biglocalstorage.user.phoneNumber,
          company: "true",
          streetAddress: biglocalstorage.user.streetAddress,
          zipCode: biglocalstorage.user.postalCode,
          city: biglocalstorage.user.city,
          companyName: biglocalstorage.user.companyName,
          orgNumber: biglocalstorage.user.companyOrgNumber,
        };

        const createresponse = await fetch("/api/user/createUser", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Country: pageData.requestContext.country,
          },
          body: JSON.stringify(createuserbody),
        });

        if (!createresponse.ok) {
          clientLogError("OrderStorageStep3RegisterPage.tsx", "createuser", "createresponse not ok", createuserbody);
          initError(translation.pages.order.paymentContainer.createNotOK, translation.pages.order.paymentContainer.createNotOKText);
          return;
        }

        const createuser = await createresponse.json();

        if (!createuser) {
          clientLogError("OrderStorageStep3RegisterPage.tsx", "createuser", "createresponse not ok", createuserbody);
          initError(translation.pages.order.paymentContainer.createNotOK, translation.pages.order.paymentContainer.createNotOKText);
          return;
        }

        if (createuser.customerID && createuser.customerID !== null) {
          if (setAllUserValues) {
            setAllUserValues({
              user: {
                loginToken: biglocalstorage.user.loginToken,
                isCompany: biglocalstorage.user.isCompany,
                isInvoice: biglocalstorage.user.isInvoice,
                forename: mitIdSession?.firstName as string || fallbackMitIdSession?.firstName as string,
                surname: mitIdSession?.surName as string || fallbackMitIdSession?.surName as string,
                dateOfBirth: mitIdSession?.dateOfBirth || fallbackMitIdSession?.dateOfBirth || biglocalstorage.user.dateOfBirth,
                invoiceEmail: biglocalstorage.user.invoiceEmail,
                invoiceReference: biglocalstorage.user.invoiceReference,
                personalNumber: biglocalstorage.user.personalNumber,
                streetAddress: biglocalstorage.user.streetAddress,
                postalCode: biglocalstorage.user.postalCode,
                city: biglocalstorage.user.city,
                email: biglocalstorage.user.email,
                phoneNumber: biglocalstorage.user.phoneNumber,
                customerId: createuser.customerID,
                companyName: biglocalstorage.user.companyName,
                companyOrgNumber: biglocalstorage.user.companyOrgNumber,
              },
            });
          }
        }
      }
    }

    setCreateChecked(true);
    return true;


  };


  //create user if needed and login 
  const onLoginSubmit = async (loginToken: string) => {
    if (!loginToken || loginToken === "") {
      clientLogError("OrderStorageStep3RegisterPage.tsx", "onLoginSubmit", "empty loginToken");
      initError(translation.pages.order.paymentContainer.loginNotOK, translation.pages.order.paymentContainer.loginNotOKText);
      return;
    }

    try {
      const createresponse = await createuser(loginToken);
      if (createresponse) {
        const response = await fetch("/api/user/login", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Country: pageData.requestContext.country,
          },
          body: JSON.stringify({ sessionId: sessionId, loginToken: loginToken }),
        });


        if (!response.ok) {
          clientLogError("OrderStorageStep3RegisterPage.tsx", "onLoginSubmit", "empty loginToken");
          initError(translation.pages.order.paymentContainer.loginNotOK, translation.pages.order.paymentContainer.loginNotOKText);
          return;
        }
        else {
          setRequestSent(true);
          router.refresh();
        }
      }
      else {
        clientLogError("OrderStorageStep3RegisterPage.tsx", "onLoginSubmit", "createresponse not ok");
        initError(translation.pages.order.paymentContainer.loginNotOK, translation.pages.order.paymentContainer.loginNotOKText);
        return;
      }
    } catch (error) {
      clientLogError("OrderStorageStep3RegisterPage.tsx", "onLoginSubmit", "catch", error);
      initError(translation.pages.order.paymentContainer.loginNotOK, translation.pages.order.paymentContainer.loginNotOKText);
      return;
    } finally {

    }

  };

  const initError = (title: string = "", message: string = "", href: string = "") => {
    if (title === "") {
      title = translation.general.errors.e500.title;
    }

    if (message === "") {
      message = translation.general.errors.e500.message;
    }

    if (href === "") {
      href = `${process.env.NEXT_PUBLIC_FRONTEND_URL}/${pageData.requestContext.country}`;
    }

    if (biglocalstorage.unit.unitId && biglocalstorage.payment.sizeCodeId) {
      setDoUnreserve(true);
    }
    setErrorTitle(title);
    setErrorText(message);
    setShowError(true);

    if (pageData.properties.unit?.Id && pageData.properties.unit?.SizeId) {
      unReserveUnit(pageData.properties.unit?.Id, pageData.properties.unit?.SizeId);
    }

    setReroutingHref(href);
  }
  useEffect(() => {

    const doUnreserveAsync = async () => {
      await unReserveUnit(biglocalstorage.unit.unitId, Number(biglocalstorage.payment.sizeCodeId));

    }
    if (doUnreserve) {
      doUnreserveAsync();
      setDoUnreserve(false);
    }
  }, [doUnreserve]);

  const unReserveUnit = async (unitId: string, sizeId: number) => {
    try {
      const response = await fetch("/api/unit/unReserveUnit", {
        method: "POST",
        headers: {
          Country: pageData.requestContext.country,
        },
        body: JSON.stringify({
          unitId: unitId,
          sizeId: sizeId,
        }),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
    } catch (error) {
      clientLogError("UnitSelection.tsx", "unReserveUnit", error);
    }
  };

  const paymentInfo = () => {
    return {
      unitId: biglocalstorage.unit.unitId,
      startDate: new Date(biglocalstorage.date.startDate).toISOString(),
      insuranceId: biglocalstorage.insurance.insuranceLedgerId,
      isCompany: biglocalstorage.user.isCompany,
      promotionCode: biglocalstorage.promotion.promotionCode,
    };
  }

  useEffect(() => {
    if ((pageData.properties.isloggedin || !pageData.properties.isloggedin) && biglocalstorage.payment !== undefined) {

      if (pageData.properties.isloggedin === false && (sessionStatus === "completed" || sessionStatus === "failed")) {
        router.refresh();
        return;
      }

      if (pageData.properties.isloggedin && sessionStatus === "failed" && !biglocalstorage.user.isInvoice && !showError) {
        oncancelSubmit();
        initError(translation.pages.order.paymentContainer.abortPayment, translation.pages.order.paymentContainer.abortPaymentText);
        return;
      }

      if (!pageData.properties.isloggedin && sessionStatus === "Failed" && !biglocalstorage.user.isInvoice && !showError) {
        initError(translation.pages.order.paymentContainer.mitidNotOK, translation.pages.order.paymentContainer.mitidNotOKText);
        return;
      }

      if (pageData.properties.isloggedin && biglocalstorage.user.isInvoice && !createChecked && !showError) {
        setReadyToCreate(true);
      }

      if (pageData.properties.isloggedin && sessionStatus !== "completed" && sessionStatus !== "failed" && !biglocalstorage.user.isInvoice && !showError) {
        setReayToStartPayment(true);
      }

      if (pageData.properties.isloggedin && sessionStatus === "completed" && biglocalstorage.payment.paymentOrderId !== "" && !biglocalstorage.user.isInvoice && !showError) {
        setCheckIfPaid(true);
      }

      if (pageData.properties.isloggedin && biglocalstorage.user.isInvoice && createChecked && !showError) {
        setReadyToRent(true);
      }

      if (pageData.properties.isloggedin && paidResponse?.paid !== undefined && !showError) {
        setreadyToCapture(true);
      }

      if (sessionStatus !== "Finished" && sessionStatus !== "Failed" && sessionStatus !== "completed" && !pageData.properties.isloggedin && !showError) {
        setMitidGo(true);
      }

      if (sessionStatus === "Finished" && !pageData.properties.isloggedin && !showError && !authOk) {
        checkLoginStatus();
        // setAuth(true);
      }
    }
  }, [biglocalstorage, sessionId, sessionStatus, paidResponse, pageData.properties.isloggedin, createChecked, showError]);

  //Check login status 
  async function checkLoginStatus(): Promise<iMitIdSessionResponse | undefined> {
    clientLogInfo("OrderStorageStep3RegisterPage.tsx", "checkLoginStatus", `checking if user is logged in, sessionId: ${sessionId}`);
    if (!sessionId || sessionId === "") {
      clientLogInfo("OrderStorageStep3RegisterPage.tsx", "checkLoginStatus", "login session has not started");
      initError(
        pageData.requestContext.translations.pages.loginPage.errors.mitidNotOK, //TODO: Change to correct translation
        pageData.requestContext.translations.pages.loginPage.errors.mitidNotOKText //TODO: Change to correct translation
      )
      return;
    }

    setLoading(true);

    try {
      const sessionresponse = await fetch(`/api/login/sessions`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          sessionId: sessionId,
          country: pageData.requestContext.country,
        },
      });

      if (!sessionresponse.ok) {
        clientLogError("OrderStorageStep3RegisterPage.tsx", "checkLoginStatus", "Session response not ok");
        initError(
          pageData.requestContext.translations.pages.loginPage.errors.mitidNotOK, //TODO: Change to correct translation
          pageData.requestContext.translations.pages.loginPage.errors.mitidNotOKText //TODO: Change to correct translation
        );
        return;
      }

      const sessionResult = await sessionresponse.json() as iMitIdSessionResponse;

      if (sessionResult.isLoggedIn) {
        setMitIdSession(sessionResult);
        setUserValue?.("forename", (sessionResult.firstName));
        setUserValue?.("surname", (sessionResult.fullName));

        if (sessionResult.dateOfBirth) {
          setUserValue?.("dateOfBirth", sessionResult.dateOfBirth);
        }

        if (sessionResult.loginToken) {
          setUserValue?.("loginToken", sessionResult.loginToken);
        }

        setAuth(true);
        return sessionResult;
      }
    } catch (error) {
      clientLogError("OrderStorageStep3RegisterPage.tsx", "checkLoginStatus", "Error", error);
      initError(
        pageData.requestContext.translations.pages.loginPage.errors.loginNotOK, //TODO: Change to correct translation
        pageData.requestContext.translations.pages.loginPage.errors.loginNotOKText //TODO: Change to correct translation
      )
    } finally {
      clientLogInfo("OrderStorageStep3RegisterPage.tsx", "checkLoginStatus", "finally");
    }
  }

  useEffect(() => {
    if (biglocalstorage.payment.paymentOrderId !== "" && redirectCheckoutHref !== "" && createChecked) {
      router.push(redirectCheckoutHref);
    }
  }, [biglocalstorage.payment.paymentOrderId, redirectCheckoutHref, createChecked]);

  useEffect(() => {
    if (authOk && biglocalstorage.user.loginToken !== "" && biglocalstorage.user.dateOfBirth !== "") {
      setSessionValue?.("sessionId", sessionId);

      const age = CalculateAge(biglocalstorage.user.dateOfBirth);

      if (age < 18) {
        initError(pageData.requestContext.translations.pages.order.paymentContainer.ageNotOK, pageData.requestContext.translations.pages.order.paymentContainer.ageNotOKText);
        return;
      }

      onLoginSubmit(biglocalstorage.user.loginToken);
    }
  }, [authOk, biglocalstorage.user.dateOfBirth, biglocalstorage.user.loginToken]);



  useEffect(() => {
    if (mitidGo) {
      loginToMitid();
    }
  }, [mitidGo]);

  useEffect(() => {
    if (readyToStartPayment) {
      startPayment();
    }
  }, [readyToStartPayment]);

  useEffect(() => {
    if (checkIfPaid) {
      paymentPaidSubmit(biglocalstorage.payment.paymentOrderId);
    }
  }, [checkIfPaid]);

  useEffect(() => {
    if (readyToCapture) {
      oncaptureSubmit();
    }
  }, [readyToCapture]);

  useEffect(() => {
    if (readyToRent) {
      rentUnit(null);
    }
  }, [readyToRent]);

  useEffect(() => {
    if (readyToRentPaid) {
      rentUnit(paidResponse);
    }
  }, [readyToRentPaid]);

  useEffect(() => {
    if (readyToCreate) {
      createuser(biglocalstorage.user.loginToken);
    }
  }, [readyToCreate]);


  const clearError = () => {
    setShowError(false);
    setErrorTitle("");
    setErrorText("");
    if (reroutingHref !== "") {
      router.push(reroutingHref);
    }
  }

  return (
    <>
      <Loader loading={loading} useFullWindow={true} useNoBackground={false} />
      <Modal
        isOpen={showError}
        onClose={clearError}
        closeTranslation={translation.general.closePopup}
      >
        <div>
          <h2>{errorTitle}</h2>
          <div>{errorText}</div>
          <LinkElement
            title={translation.general.close}
            isButton={true}
            onClick={clearError}
            buttonColorTheme={eButtonColorTheme.DarkContrast}
            className="w-full mt-6"
          >
            {translation.general.close}
          </LinkElement>

        </div>
      </Modal>
    </>
  );
}

