import { iSelectItem } from "../models/componentModels/selectItem";

export const formatDate = (dateString: string): string => {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');

  return `${year}-${month}-${day}`;
};

export const formatDateByCountry = (dateString: string | Date | undefined, country: string): string => {
  if (!dateString) {
    return "";
  }

  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const fullYear = String(date.getFullYear());
  const year = fullYear.slice(-2);

  switch (country.toLowerCase()) {
    case "no":
    case "nb":
      return `${day}/${month}/${year}`;
    case "se":
    case "sv":
      return `${year}-${month}-${day}`;
    case "dk":
    case "da":
    default:
      return `${day}/${month}/${year}`;
  }
};

export const isValidDate = (dateString: string | Date | undefined): boolean => {
  if (!dateString) {
    return false;
  }

  const date = new Date(dateString);

  if (!(date instanceof Date) || isNaN(date.getTime())) {
    return false;
  }

  const minDate = new Date(1970,0, 1);
  return date.getTime() > minDate.getTime();
}

export const formatDateFromDate = (dateInput: Date): string => {
  const date = new Date(dateInput);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');

  return `${year}-${month}-${day}`;
};

export const formatDateToLocal = (dateString: string, culture: string): string => {
  const dateFormatter: Intl.DateTimeFormat = new Intl.DateTimeFormat(culture, {
    year: "numeric",
    month: "short",
    day: "numeric",
  });

  return dateFormatter.format(new Date(dateString));
};

export const TwoDigitFormattedDate = (dateString: string, culture: string): string => {
  const dateFormatter: Intl.DateTimeFormat = new Intl.DateTimeFormat(culture, {
    day: '2-digit',
    month: '2-digit',
  });

  return dateFormatter.format(new Date(dateString));
};

export const getBookableDateOptions = (todayDate: Date, daysAhead: number, bookingFrom: Date, bookingTo: Date, culture: string, country: string): { selectItem: iSelectItem, date: Date }[] => {
  let bookingAheadDays = daysAhead - 1;

  if (bookingTo && bookingFrom) {
    bookingTo = new Date(bookingTo);
    bookingFrom = new Date(bookingFrom);
    bookingTo.setHours(0, 0, 0);
    bookingFrom.setHours(0, 0, 0);
    const timeDifference = bookingTo.getTime() - bookingFrom.getTime();
    const daysDifference = Math.ceil(timeDifference / (1000 * 3600 * 24));

    bookingAheadDays = daysDifference;
  }

  const maxDate = new Date(bookingFrom || todayDate);
  maxDate.setDate((bookingFrom || todayDate).getDate() + bookingAheadDays);

  const createDateArray = (
    startDate: Date,
    endDate: Date
  ): { id: number; date: Date }[] => {
    const dates: { id: number; date: Date }[] = [];
    let currentDate = new Date(startDate);
    let id = 0;

    while (currentDate <= endDate) {
      dates.push({ id, date: new Date(currentDate) });
      currentDate.setDate(currentDate.getDate() + 1);
      id++;
    }
    return dates;
  };

  const dateArray = createDateArray(bookingFrom || todayDate, maxDate);

  const dateOptions: { selectItem: iSelectItem, date: Date }[] = dateArray.map((date) => {
    let weekday = date.date.toLocaleDateString(culture, { weekday: "long" });
    weekday = weekday.charAt(0).toUpperCase() + weekday.slice(1);
    const formattedDate = formatDateByCountry(date.date.toISOString(), country);
    return {
      selectItem: {
        value: date.id.toString(),
        label: `${weekday} ${formattedDate}`,
      },
      date: date.date
    };
  });

  return dateOptions;
};
