'use client';
import { iCampaignBlock } from '@/app/models/blockTypes/campaignBlock';
import { iRequestContext } from '@/app/models/contextModels/requestContext';
import { iCampaignPage } from '@/app/models/pageTypes/campaignPage';
import styles from './campaignblock.module.scss';
import { logError } from '@/app/helpers/azureBlobLogger';

interface Props {
    block: iCampaignBlock;
    requestContext: iRequestContext;
}


export default function CampaignBlock({ block, requestContext }: Props) {
    try {
        if (!block || !block.properties || !block.properties.campaignPage) {
            return (
                <></>
            );
        }

        const campaignPage = block?.properties?.campaignPage[0] as iCampaignPage;

        return (
            <div className="p-6 xl:p-0">
                <div className={`w-full GetBlockWrapperClasses(block)`} >
                    <div className={styles.campaignBlock}>
                        <a
                            href={campaignPage.route.path}
                            className={`${styles.campaignLink} inline-block w-full`}
                            title={`${requestContext.translations.general.readMore} ${requestContext.translations.general.about} "${block.properties.campaignText}"`}
                            aria-label={`${requestContext.translations.general.readMore} ${requestContext.translations.general.about} "${block.properties.campaignText}"`}
                        >
                            <span className={`${styles.newsCampaign} inline mr-2`}>{requestContext.translations.general.new}</span>
                            <span className={`${styles.campaignText} inline`}>{block.properties.campaignText}</span>
                            <span className={`${styles.campaignReadMore}  inline ml-2`}>{requestContext.translations.general.readMore}</span>
                        </a>
                    </div >
                </div>
            </div>
        );
    } catch (error) {
        logError("CampaignBlock.tsx", 'Error when generating CampaignBlock', `current url: ${requestContext.url}`, 'Error', error);
    }
}
