import RichText from '@/app/components/RichText/RichText';
import { iContentColumnsBlock } from '@/app/models/blockTypes/contentColumnsBlock';
import { iRequestContext } from '@/app/models/contextModels/requestContext';
import { iRichText } from '@/app/models/richText';
import styles from './contentcolumnsblock.module.scss';
import { GetBlockWrapperClasses } from '@/app/helpers/clientUtilityHelper';
import { logError } from '@/app/helpers/azureBlobLogger';

interface Props {
    block: iContentColumnsBlock;
    requestContext: iRequestContext;
}


export default function ContentColumnsBlock({ block, requestContext }: Props) {
    try {
        const columns: iRichText[] = [
            block.properties.column1,
            block.properties.column2,
            block.properties.column3,
            block.properties.column4,
        ].filter(Boolean) as iRichText[]; // Filter out undefined columns

        return (
            <div className={GetBlockWrapperClasses(block)}>
                <div className={`p-4 ${styles.contentColumnBlock}`}>
                    <div className={columns.length === 1 ? 'flex flex-col lg:flex-row gap-10 ' : 'text-center'}>
                        <h2 className={`${columns.length === 1 ? 'lg:w-1/2' : 'w-full mb-10'} text-left`}>{block.properties.title}</h2>
                        {columns.length === 1 && (
                            <RichText className="lg:w-1/2" text={block.properties.column1} />
                        )}
                    </div>

                    {columns.length > 1 && (
                        <div className={`grid grid-cols-1 gap-10 ${columns.length === 2 ? 'lg:grid-cols-2' : ''} ${columns.length === 3 ? 'lg:grid-cols-3' : ''} ${columns.length === 4 ? 'lg:grid-cols-4' : ''}`}>
                            {columns.map((column, index) => (
                                <RichText key={index} text={column} />
                            ))}
                        </div>
                    )}
                </div>
            </div>
        );
    } catch (error) {
        logError("ContentColumnsBlock.tsx", 'Error when generating ContentColumnsBlock', `current url: ${requestContext.url}`, 'Error', error);
    }
}
