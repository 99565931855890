'use client';
import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import styles from './modal.module.scss';

interface ModalProps {
    isOpen: boolean;
    onClose: () => void;
    children: React.ReactNode;
    closeTranslation: string;
    className?: string;
    showFrame?: boolean;
    blurBackground?: boolean;
}

const Modal: React.FC<ModalProps> = ({ isOpen, onClose, children, closeTranslation, className = "", showFrame = true, blurBackground = true }) => {
    const [isClient, setIsClient] = useState(false);
    const [scrollYPosition, setScrollYPosition] = useState(0);

    useEffect(() => {
        setIsClient(true);
    }, []);

    useEffect(() => {
        if (isOpen) {
            const scrollY = window.scrollY;
            setScrollYPosition(scrollY);
            document.body.style.position = 'fixed';
            document.body.style.top = `-${scrollY}px`;
            document.body.style.width = '100%'; // Ensure no horizontal scroll
        } else {
            document.body.style.position = '';
            document.body.style.top = '';
            document.body.style.width = ''; // Reset width
            window.scrollTo(0, scrollYPosition);
        }
    }, [isOpen]);

    useEffect(() => {
        const handleScroll = () => {
            document.documentElement.style.setProperty('--scroll-y', `${window.scrollY}px`);
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    if (!isOpen || !isClient) return null;

    const onModalClose = () => {
        document.body.style.position = '';
        document.body.style.top = '';
        document.body.style.width = ''; // Reset width
        window.scrollTo(0, scrollYPosition);
        onClose();
    };

    let cssClasses = `${styles.modalContent} ${className}`;
    let backdropClasses = `${styles.modalBackdrop} ${blurBackground ? styles.modalBackdropBlur : ''}`;

    return ReactDOM.createPortal(
        <div className={backdropClasses}>
            {showFrame ? (
                <div className={`rounded-3xl p-10 bg-white ${cssClasses} `} style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    overflow: 'hidden' // Prevent overflow
                }}>
                    <button
                        className={styles.modalClose}
                        onClick={onModalClose}
                        aria-label={closeTranslation}
                    ></button>
                    {children}
                </div>
            ) : (
                <div className={styles.modalContentNoFrame}>
                    <button
                        className={`${styles.modalClose} ${styles.modalCloseTopRight} ${styles.modalCloseOutline} rounded-full`}
                        onClick={onModalClose}
                        aria-label={closeTranslation}
                    ></button>
                    {children}
                </div>
            )}
        </div>,
        document.body
    );
};

export default Modal;