"use client";
import React, { useEffect, useRef, useState } from "react";
import Image from "next/image";
import { iHeroNoticesProps } from "@/app/models/heroNotice";
import { iRequestContext } from "@/app/models/contextModels/requestContext";
import styles from "./heronotice.module.scss";
import { iFlattenedArea } from "@/app/models/componentModels/flattenedArea";
import LinkElement from "../General/LinkElement";
import { eChevronColor } from "@/app/models/enum/chevronColor";
import { eChevronPosition } from "@/app/models/enum/chevronPosition";
import { eLinkSize } from "@/app/models/enum/linkSize";
import { FlattenAreaHierarchy, GetAbsoluteUrlClient, SearchFlattenedAreaHierarchy } from "@/app/helpers/clientUtilityHelper";
import { clientLogInfo } from "@/app/helpers/clientLoggingHelper";
import { GetUserCookieConsentLevelsClient } from "@/app/helpers/cookieHelperClient";

export interface Props {
    props: iHeroNoticesProps;
    requestContext: iRequestContext;
}

export default function HeroNotice({ props, requestContext }: Props) {
    const [query, setQuery] = useState("");
    const [results, setResults] = useState<iFlattenedArea[]>([]);
    const [flattenedAreas, setFlattenedAreas] = useState<iFlattenedArea[]>([]);
    const areas = requestContext.configurations.areas;
    const searchBoxRef = useRef<HTMLDivElement>(null);
    const resultPopupRef = useRef<HTMLDivElement>(null);
    const searchInputRef = useRef<HTMLInputElement>(null);
    clientLogInfo('HeroNotice', 'HeroNotice component loaded');

    useEffect(() => {
        const flattened = FlattenAreaHierarchy(areas);
        setFlattenedAreas(flattened);
    }, [areas]);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (
                searchBoxRef.current &&
                !searchBoxRef.current.contains(event.target as Node) &&
                resultPopupRef.current &&
                !resultPopupRef.current.contains(event.target as Node)
            ) {
                setResults([]);
            }
        };

        const handleFocusInside = () => {
            if (query.length >= 3) {
                const searchResults = SearchFlattenedAreaHierarchy(query, flattenedAreas);
                setResults(searchResults);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);

        if (searchInputRef.current) {
            searchInputRef.current.addEventListener("focusin", handleFocusInside);
        }

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);

            if (searchInputRef.current) {
                searchInputRef.current.removeEventListener("focusin", handleFocusInside);
            }
        };
    }, [query, areas]);

    useEffect(() => {
        if (query.length < 3) {
            setResults([]);
            return;
        }

        const searchResults = SearchFlattenedAreaHierarchy(query, flattenedAreas);
        setResults(searchResults);
    }, [query, areas]);

    const useTwoColumnMobileStepsGrid = props.steps && props.steps.length > 3 && props.steps.length % 2 === 0;
    const rightContainerClasses = props.useImage ? "md:col-span-3 lg:col-span-3 puff relative hidden md:block" : `hidden md:grid ${useTwoColumnMobileStepsGrid ? "grid-cols-2" : "grid-cols-1"} md:grid-cols-2 lg:grid-cols-2 gap-6 mb-10 h-full`;

    const handleButtonClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        if (props.buttonLink && props.buttonLink.startsWith('#')) {
            event.preventDefault();
            const targetElement = document.querySelector(props.buttonLink);
            if (targetElement) {
                const top = targetElement.getBoundingClientRect().top + window.pageYOffset - 90;
                window.scrollTo({ top, behavior: 'smooth' });
            }
        }
    };

    return (
        <div className={`${!props.isBlock ? "bg-medium-gs-green p-6 pt-0 lg:pt-6 " : ""} ${!props.isConnectedBelow ? "xl:rounded-3xl" : "xl:rounded-t-3xl"}`}>
            <div className={`grid grid-cols-1 ${props.useImage ? 'md:grid-cols-5 lg:grid-cols-5' : 'md:grid-cols-2 lg:grid-cols-2'} gap-12 relative`}>
                <div className={props.useImage ? "md:col-span-2 lg:col-span-2 puff" : "puff"}>
                    <h1 className={`${props.subTitle || props.smallSubTitle ? "mb-1" : ""}`}>{props.title}</h1>
                    {props.subTitle && <h2 className="mb-3">{props.subTitle}</h2>}
                    {props.smallSubTitle && <div className="mb-3">{props.smallSubTitle}</div>}
                    <p className="text-lg mt-4">{props.preamble}</p>
                    <ul className={`advantages font-bold mb-6 ${styles.checkList}`}>
                        {props.checklist && props.checklist.map((checkItem, index) => (
                            <li className="flex mt-1" key={index}>
                                <Image
                                    src="/assets/img/icons/check_black.svg"
                                    width={24}
                                    height={24}
                                    alt="Checkmark"
                                    className="mr-1 h-[24px] w-[24px]"
                                />
                                {checkItem}
                            </li>
                        ))}
                    </ul>
                    {props.useSearchbox && (
                        <>
                            <div className={`relative ${styles.searchFunctionWrapper}`}>
                                <div className={`mt-4 ${styles.searchBoxContainer}`} ref={searchBoxRef}>
                                    <input
                                        type="text"
                                        placeholder={props.searchBoxPlaceholder}
                                        className="px-5"
                                        value={query}
                                        onChange={(e) => setQuery(e.target.value)}
                                        ref={searchInputRef}
                                    />
                                    <div className={`${styles.buttonWrapper}`}>
                                        <a href={requestContext.configurations.pages.allFacilities.url} target="_self" className={styles.iconLink} >
                                            <Image
                                                src="/assets/img/icons/icon_location.svg"
                                                width={20}
                                                height={20}
                                                alt={requestContext.translations.blocks.heroNotice.location}
                                            />
                                        </a>
                                        <a className={`btn ${styles.searchButton}`} href={requestContext.configurations.pages.allFacilities.url} target="_self">
                                            <span>{props.searchButtonText}</span>
                                        </a>
                                    </div>
                                </div>
                                {query && results && results.length > 0 && (
                                    <div className={styles.searchResultContainer}>
                                        <div className={styles.searchResults} ref={resultPopupRef}>
                                            {results.map(({ area, facilities }) => (
                                                <div key={area.id}>
                                                    <LinkElement
                                                        title={area.name}
                                                        href={area.path}
                                                        chevronColor={eChevronColor.Green}
                                                        chevronPosition={eChevronPosition.Right}
                                                        linkSize={eLinkSize.Large}
                                                        className="mb-3"
                                                    >
                                                        {area.name}
                                                    </LinkElement>
                                                    <ul className="pl-2">
                                                        {facilities.map((facility, index) => (
                                                            <div key={index} className="mb-3">
                                                                <a
                                                                    title={facility.name}
                                                                    href={facility.path}
                                                                    className={`${styles.facilityLink}`}>
                                                                    <div >
                                                                        <div className={styles.facilityName}>{`${facility.city} - ${facility.name}`}</div>
                                                                        <div className={`no-underline ${styles.facilityAddress}`} style={{ textDecoration: 'none' }}>
                                                                            {facility.streetAddress}
                                                                        </div>
                                                                    </div>
                                                                </a>
                                                            </div>
                                                        ))}
                                                    </ul>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                )}
                            </div>
                            <LinkElement
                                title={props.allFacilitiesLinkText ?? requestContext.translations.general.showAllFacilitiesAndStorages}
                                href={requestContext.configurations.pages.allFacilities.url}
                                isStandalone={true}
                                chevronPosition={eChevronPosition.None}
                                linkSize={eLinkSize.Standard}
                                className={`${styles.showAllFacilitiesLink} textSmall mt-2 mb-0-i`}
                            >
                                {props.allFacilitiesLinkText ?? requestContext.translations.general.showAllFacilitiesAndStorages}
                            </LinkElement>
                        </>
                    )}

                    {!props.useSearchbox && (
                        <a href={props.buttonLink} className="btn inline-block text-center rounded-3xl bg-gs-green text-white no-underline p-3 pr-10 pl-10 w-full lg:w-auto"  onClick={handleButtonClick}>
                            {props.buttonText}
                        </a>
                    )}

                </div>
                <div className={rightContainerClasses}>
                    {props.useImage && props.image && (
                        <div className="relative w-full overflow-hidden lg:h-full">
                            <div className="relative w-full h-0 pb-[100%] lg:pb-0 lg:h-full">
                                {props.image && props.image.length > 0 && (
                                    <Image
                                        src={GetAbsoluteUrlClient(props.image[0].url)}
                                        fill
                                        alt={props.title}
                                        className="rounded-3xl object-cover object-center"
                                        sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw" // Add sizes prop
                                    />
                                )}
                                {props.orangeNotice && (
                                    <div className={styles.diagonalBanner}>
                                        <div className="mt-5 h3">{props.orangeNotice}</div>
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                    {!props.useImage && props.steps && props.steps.map((step, index) => (
                        <div
                            className="p-6 lg:p-10 bg-white rounded-3xl flex flex-col items-center justify-center text-center h-full"
                            key={index}
                        >
                            <div className="flex justify-center size-20 m-auto">
                                <img src={step.image} alt={step.text} />
                            </div>
                            <h3 className="text-center mb-0-i hidden lg:block">
                                {step.text}
                            </h3>
                            <h4 className="text-center mb-0-i block lg:hidden">
                                {step.text}
                            </h4>
                        </div>
                    ))}
                </div>
            </div>
        </div >
    );
}
