import RichText from '@/app/components/RichText/RichText';
import { logError } from '@/app/helpers/azureBlobLogger';
import { GetBlockWrapperClasses } from '@/app/helpers/clientUtilityHelper';
import { iWysiwygBlock } from '@/app/models/blockTypes/wysiwygBlock';
import { iRequestContext } from '@/app/models/contextModels/requestContext';

interface Props {
    block: iWysiwygBlock;
    requestContext: iRequestContext;
}


export default function WysiwygBlock({ block, requestContext }: Props) {
    try {
        return (
            <div className={`${GetBlockWrapperClasses(block)} wysiwyg-block`}>
                <h2 className='font-bold text-[23px] lg:text-4xl'>{block.properties.title}</h2>
                <RichText text={block.properties.mainBody} />
            </div>
        );
    } catch (error) {
        logError("WysiwygBlock.tsx", 'Error when generating WysiwygBlock', `current url: ${requestContext}`, 'Error', error);
    }
}
