'use client';

import React, { useState } from 'react';
import styles from './profilechanger.module.scss';
import { iMyPagesUser } from '@/app/models/myPages/myPagesUser';
import Modal from '../Modal/Modal';
import Loader from '../Loader/Loader';

interface ProfileChangerProps {
    translations: any;
    userProfiles: iMyPagesUser[];
    onProfileChange: (selectedProfile: iMyPagesUser) => void;
}

const ProfileChanger: React.FC<ProfileChangerProps> = ({ userProfiles, translations, onProfileChange }) => {
    const [loading, setLoading] = useState<boolean>(false);
    const lastSelectedCustomerId = localStorage.getItem('lastSelectedCustomerId');
    const initialProfile: iMyPagesUser | null = userProfiles.find(profile => 
    (profile.userDataPerson && profile.userDataPerson.customerId === lastSelectedCustomerId) ||
    (profile.userDataOrganisation && profile.userDataOrganisation.customerId === lastSelectedCustomerId)
) || (userProfiles.length > 0 ? userProfiles[0] : null);

    const [currentProfile, setCurrentProfile] = useState(initialProfile);
    const [isChangeProfileModalOpen, setIsChangeProfileModalOpen] = useState(false);

    const handleOpenChangeProfileModal = () => {
        setIsChangeProfileModalOpen(true);
    };

    const handleChangeProfileModal = (selectedProfile: iMyPagesUser) => {
        setLoading(true);
        setCurrentProfile(selectedProfile);
        setIsChangeProfileModalOpen(false);
        onProfileChange(selectedProfile);
        setTimeout(() => {
            setLoading(false);
        }, 500);
    };

    const handleCloseChangeProfileModal = () => {
        setIsChangeProfileModalOpen(false);
    };

    const getIsPerson = (profile: iMyPagesUser): boolean => {
        const isPerson = profile.userDataPerson ? true : false;
        return isPerson
    }
    const getProfileName = (profile: iMyPagesUser): string => {
        return getIsPerson(profile) ? `${profile.userDataPerson?.forename} ${profile.userDataPerson?.surname}` : profile.userDataOrganisation?.orgName ?? '';
    }

    const getIdentityNumber = (profile: iMyPagesUser): string => {
        const identityNumber = getIsPerson(profile) ? "" : profile.userDataOrganisation?.orgNumber ?? '';
        if (identityNumber.length > 4) {
            return `${identityNumber.slice(0, -4)}-${identityNumber.slice(-4)}`;
        }

        return identityNumber;
    }

    const getCustomerId = (profile: iMyPagesUser) => {
        return profile.userDataPerson ? profile.userDataPerson.customerId : profile.userDataOrganisation?.customerId;
    }

    return (
        <>
            {userProfiles.length > 1 && currentProfile && (
                <div className={`${styles.profileContainer} w-full lg:w-auto flex items-center justify-end`}>
                    <span className="mr-3 currentProfile">Aktuell profil: <span className="font-semibold">{getProfileName(currentProfile)}</span></span>
                    <button onClick={() => handleOpenChangeProfileModal()} className={`btn ${styles.changeProfileButton}`}>
                        <span className="text hidden lg:inline-block">{translations.pages.myPagesPage.changeAccount}</span>
                        <img src="/assets/img/icons/icon_switch_white.svg" alt="Switch Icon" className="icon-switch inline-block lg:hidden" />
                        <img src="/assets/img/icons/icon_profile_white.svg" alt="Profile Icon" className="icon-profile inline-block lg:hidden" />
                    </button>
                </div>
            )}
            <Loader loading={loading} useFullWindow={true} />

            <Modal
                isOpen={isChangeProfileModalOpen}
                onClose={handleCloseChangeProfileModal}
                closeTranslation={translations.general.closePopup}
                className='w-600-responsive' >
                <div>
                    <h2>{translations.pages.myPagesPage.youHaveMultipleAccounts}</h2>
                    <div className="lg:pl-7 pr-2 py-2">
                        {userProfiles.map((profile, index) => (
                            <div key={index} className="mb-4 grid grid-cols-1 lg:grid-cols-12 gap-4 ">
                                <div className="col-span-1 lg:col-span-5 flex items-center pb-2"><i className={getIsPerson(profile) ? "far fa-user" : "far fa-building"}></i>&nbsp;{getProfileName(profile)}</div>
                                <div className="col-span-1 lg:col-span-4 flex items-center pb-2">{getIdentityNumber(profile)}</div>
                                <div className="col-span-1 lg:col-span-3 flex items-center">
                                    <button
                                        className={"btn w-full"}
                                        onClick={() => handleChangeProfileModal(profile)}
                                        disabled={currentProfile == null ||  getCustomerId(profile) === getCustomerId(currentProfile)}
                                    >
                                        {translations.pages.myPagesPage.choose}
                                    </button>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="flex flex-row gap-4 mt-10">
                        <button className='btn w-full dark-contrast' onClick={handleCloseChangeProfileModal}>{translations.general.close}</button>
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default ProfileChanger;