"use client";

import React from "react";
import styles from "./login.module.scss";
import { clientLogError, clientLogInfo, clientLogWarn } from "@/app/helpers/clientLoggingHelper";
import { useEffect, useState } from "react";
import Loader from "@/app/components/Loader/Loader";
import { useRouter } from "next/navigation";
import { doUseOrderStorageStore } from "@/stores/order-storage-store";
import { useShallow } from "zustand/react/shallow";
import useStore from "@/stores/use-store";
import FormInput from "../Forms/FormInput";
import LinkElement from "../General/LinkElement";
import { eButtonColorTheme } from "@/app/models/enum/eButtonColorTheme";
import Modal from "../Modal/Modal";
import { trim } from "lodash";
import { iRichText } from "@/app/models/richText";

export interface Props {
    sessionStatus: string | string[] | undefined;
    sessionId: string | string[] | undefined;
    isLoggedIn: boolean;
    myPagesLink: string;
    country: string;
    language: string;
    translations: any;
    isCPRLogin: boolean;
    fallbackTitle: string;
    fallbackText: iRichText;
}

interface LoginResponse {
    data: {
        errors: [];
        id: string;
        redirect_url: string;
        status: string;
    };
}

export default function Login({ sessionStatus, sessionId, isLoggedIn, myPagesLink, country, language, translations, isCPRLogin, fallbackTitle, fallbackText }: Props) {
    const setValue = useStore(
        doUseOrderStorageStore,
        useShallow((state) => state)
    )?.setSessionValue;

    const setUserValue = useStore(
        doUseOrderStorageStore,
        useShallow((state) => state)
    )?.setUserValue;

    const [requestSent, setRequestSent] = useState<boolean>(false);
    const [cprRequestSent, setCPRRequestSent] = useState<boolean>(false);
    const [uniqueId, setUniqueId] = useState<string>("");
    const [showError, setShowError] = useState<boolean>(false);
    const [errorButtonText, setErrorButtonText] = useState<string>("");
    const [errorTitle, setErrorTitle] = useState<string>("");
    const [errorText, setErrorText] = useState<string>("");
    const user = doUseOrderStorageStore(useShallow((state) => state.user));
    const [loading, setLoading] = useState<boolean>(sessionStatus === "Finished" && sessionId !== undefined && sessionId !== null);
    const session = doUseOrderStorageStore(useShallow((state) => state.session));
    const [mydata, setMyData] = useState<LoginResponse | null>(null);
    const [myCPRData, setMyCPRData] = useState<LoginResponse | null>(null);
    const [isLoggingIn, setIsLoggingIn] = useState<boolean>(false);
    const [isFallbackLogin, setIsFallbackLogin] = useState<boolean>(isCPRLogin);
    const [showFallbackLogin, setShowFallbackLogin] = useState<boolean>(false);
    const [sessionInfo, setSessionInfo] = useState<iMitIdSessionResponse | null>(null);
    const router = useRouter();

    //STEP 1: Check if user is logged in
    useEffect(() => {
        if (isLoggedIn) {
            clientLogInfo("LoginUserPage.tsx", "isLoggedIn", `User is logged in, redirecting to "${myPagesLink}"`);
            router.push(myPagesLink)
        }
    }, [isLoggedIn]);

    function isValidCPR(cpr: string): boolean {
        //TODO: Add more validation if needed
        return cpr.length === 10;
    }

    function isOver18(cpr: string): boolean {
        const day = parseInt(cpr.substring(0, 2), 10);
        const month = parseInt(cpr.substring(2, 4), 10);
        const year = parseInt(cpr.substring(4, 6), 10);
        const centuryIndicator = parseInt(cpr.charAt(6), 10);

        let fullYear;
        
        if (centuryIndicator >= 0 && centuryIndicator <= 3) {
            fullYear = 1900 + year;
        } else if (centuryIndicator === 4) {
            if (year >= 0 && year <= 36) {
                fullYear = 2000 + year;
            } else {
                fullYear = 1900 + year;
            }
        } else if (centuryIndicator >= 5 && centuryIndicator <= 8) {
            if (year >= 0 && year <= 57) {
                fullYear = 2000 + year;
            } else {
                fullYear = 1800 + year;
            }
        } else if (centuryIndicator === 9) {
            if (year >= 0 && year <= 36) {
                fullYear = 2000 + year;
            } else {
                fullYear = 1900 + year;
            }
        } else {
            throw new Error("Invalid CPR number");
        }

        const birthDate = new Date(fullYear, month - 1, day);

        var age = new Date().getFullYear() - birthDate.getFullYear();
        const monthDiff = new Date().getMonth() - birthDate.getMonth();
        const dayDiff = new Date().getDate() - birthDate.getDate();

        if (monthDiff < 0 || (monthDiff === 0 && dayDiff < 0)) {
            age--;
        }

        return age >= 18;
    }

    //Go to mitid  
    const handleSubmit = async () => {
        try {
            clientLogInfo("LoginUserPage.tsx", "handleSubmit", "handling submit");
            setRequestSent(false);

            setLoading(true);

            clientLogInfo("LoginUserPage.tsx", "handleSubmit", "URL", window.location.href, `Sending request to Zignsec MITID API`);
            const fullUrl = window.location.href;
            const urlObj = new URL(fullUrl);
            const returnUrl = urlObj.pathname + urlObj.search + urlObj.hash;

            const response = await fetch(`/api/login/mitidauto`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Country: country,
                },
                body: JSON.stringify({
                    url: returnUrl,
                    mitidText: translations.pages.order.paymentContainer.mitidTextBody,
                    language: language
                }),
            });

            if (!response.ok) {
                var responseData = await response.json();
                initError(
                    translations.pages.loginPage.errors.mitidNotOK,
                    translations.pages.loginPage.errors.mitidNotOKText
                )
            }

            const data = await response.json();
            clientLogInfo("LoginUserPage.tsx", "handleSubmit", `User sessionId: ${data.data.id}`);
            if (data.data.id !== "") {
                clientLogInfo("LoginUserPage.tsx", "handleSubmit", "myData", data);
                setMyData(data);
                setRequestSent(true);
            } else {
                initError(
                    translations.pages.loginPage.errors.mitidNotOK,
                    translations.pages.loginPage.errors.mitidNotOKText
                )
            }
        } catch (error) {
            clientLogError("LoginUserPage.tsx", "handleSubmit", "Error", error);
            initError(
                translations.pages.loginPage.errors.mitidNotOK,
                translations.pages.loginPage.errors.mitidNotOKText
            )
        }
    }

    const handleCPRSubmit = async () => {
        try {
            clientLogInfo("LoginUserPage.tsx", "handleCPRSubmit", "handling submit");
            setRequestSent(false);
            const trimmedUniqueId = uniqueId.trim();

            if (!isValidCPR(trimmedUniqueId)) {
                clientLogWarn("LoginUserPage.tsx", "handleCPRSubmit", "Invalid personal number", `Personal number: ${trimmedUniqueId}`);
                initError(
                    translations.pages.loginPage.errors.wrongPersonalNumberTitle,
                    translations.pages.loginPage.errors.wrongPersonalNumberText
                )
                return;
            }

            if (!isOver18(trimmedUniqueId)) {
                clientLogWarn("LoginUserPage.tsx", "handleCPRSubmit", "User is under 18", `Personal number: ${trimmedUniqueId}`);
                initError(
                    translations.pages.loginPage.errors.mustBe18Title,
                    translations.pages.loginPage.errors.mustBe18Text
                )
                return;
            }

            setLoading(true);
            setShowFallbackLogin(false);

            clientLogInfo("LoginUserPage.tsx", "handleCPRSubmit", "URL", window.location.href, `Sending request to Zignsec MITID API with personal number: ${trimmedUniqueId}`);
            const fullUrl = window.location.href;
            const urlObj = new URL(fullUrl);
            const returnUrl = urlObj.pathname + urlObj.search + urlObj.hash;

            const response = await fetch(`/api/login/mitid`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Country: country,
                },
                body: JSON.stringify({
                    cpr: trimmedUniqueId,
                    url: returnUrl,
                    mitidText: translations.pages.order.paymentContainer.mitidTextBody,
                }),
            });

            if (!response.ok) {
                var responseData = await response.json();
                initError(
                    translations.pages.loginPage.errors.mitidNotOK,
                    translations.pages.loginPage.errors.mitidNotOKText
                )
            }

            const data = await response.json();
            clientLogInfo("LoginUserPage.tsx", "handleCPRSubmit", `User personal number: ${trimmedUniqueId}`);
            if (trimmedUniqueId !== "") {
                clientLogInfo("LoginUserPage.tsx", "handleCPRSubmit", "myData", data);
                setUserValue?.("personalNumber", trimmedUniqueId);
                setMyCPRData(data);
                setCPRRequestSent(true);
            } else {
                initError(
                    translations.pages.loginPage.errors.mitidNotOK,
                    translations.pages.loginPage.errors.mitidNotOKText
                )
            }
        } catch (error) {
            clientLogError("LoginUserPage.tsx", "handleCPRSubmit", "Error", error);
            initError(
                translations.pages.loginPage.errors.mitidNotOK,
                translations.pages.loginPage.errors.mitidNotOKText
            )
        }
    }

    //STEP 2/6 - loginuser if authentication is successfull
    useEffect(() => {
        if (sessionStatus === "Finished" && sessionId && !isLoggingIn) {
            setIsLoggingIn(true);
            clientLogInfo("LoginUserPage.tsx", `sessionStatus: ${sessionStatus}, sessionId: ${sessionId}`, "session", session);
            setValue?.("sessionId", sessionId.toString());
            checkLogin(sessionId.toString());
        } else if (sessionStatus === "Finished" && sessionId && !isLoggingIn) {

        } else if (sessionStatus === "Failed") {
            initError(
                translations.pages.loginPage.errors.mitidNotOK,
                translations.pages.loginPage.errors.mitidNotOKText
            )
        }
    }, [sessionStatus, sessionId, session.sessionId]);

    useEffect(() => {
        if (sessionInfo && sessionInfo.isLoggedIn) {
            onLoginSubmit(sessionInfo.loginToken);
        }
    }, [sessionInfo]);

    //STEP 3 - send to login zignsec
    useEffect(() => {
        if (mydata?.data.redirect_url && requestSent === true) {
            clientLogInfo("LoginUserPage.tsx", `redirecting to "${mydata.data.redirect_url}"`, "mydata", mydata);
            router.push(mydata.data.redirect_url);
        } else if (!mydata?.data.redirect_url) {
            clientLogError("LoginUserPage.tsx", "redirect mydata", "Redirect URL is not defined");
        } else {
            setLoading(false);
            clientLogError("LoginUserPage.tsx", "redirect mydata", "Request has not been sent");
        }
    }, [mydata]);

    //For CPR login
    useEffect(() => {
        if (isFallbackLogin) {
            if (myCPRData?.data.redirect_url && cprRequestSent === true) {
                clientLogInfo("LoginUserPage.tsx", "CPR Login", `redirecting to "${myCPRData.data.redirect_url}"`, "myCPRData", myCPRData);
                router.push(myCPRData.data.redirect_url);
            } else if (!myCPRData?.data.redirect_url) {
                clientLogError("LoginUserPage.tsx", "CPR Login", "redirect myCPRData", "Redirect URL is not defined");
            } else {
                setLoading(false);
                clientLogError("LoginUserPage.tsx", "CPR Login", "redirect myCPRData", "Request has not been sent");
            }
        }
    }, [myCPRData]);

    // STEP 5 - Get spacemanager user by identity number
    const GetSpaceManagerUsersByToken = async (uniqueIdentifier: string) => {
        clientLogInfo("LoginUserPage.tsx", "GetSpaceManagerUsersByToken", `Getting user with unique identifier: ${uniqueIdentifier}`);
        const timespan = new Date().getTime();

        const useresponse = await fetch(`/api/user/getSpaceManagerUsersByToken?timespan=${timespan}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                loginToken: uniqueIdentifier,
                filter: "false",
                companyNumber: "",
                Country: country,
            },
        });

        if (!useresponse.ok) {
            clientLogError("LoginUserPage.tsx", "GetSpaceManagerUsersByToken", "Network response was not ok");
            setLoading(false);
            throw new Error("Network response was not ok");
        }
        return await useresponse.json();
    };

    const GetSpaceManagerUsersByIdentityNumber = async (uniqueIdentifier: string) => {
        clientLogInfo("LoginUserPage.tsx", "GetSpaceManagerUsersByIdentityNumber", `Getting user with unique identifier: ${uniqueIdentifier}`);
        const timespan = new Date().getTime();

        const useresponse = await fetch(`/api/user/getSpaceManagerUsersByIdentityNumber?timespan=${timespan}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                personalNumber: uniqueIdentifier,
                filter: "false",
                companyNumber: "",
                Country: country,
            },
        });

        if (!useresponse.ok) {
            clientLogError("LoginUserPage.tsx", "GetSpaceManagerUsersByIdentityNumber", "Network response was not ok");
            setLoading(false);
            throw new Error("Network response was not ok");
        }
        return await useresponse.json();
    };

    //STEP 4 - Check login success
    const checkLogin = async (sessionId: string) => {
        clientLogInfo("LoginUserPage.tsx", "checkLogin", `checking if user is logged in, sessionId: ${sessionId}`);
        if (!sessionId || sessionId === "") {
            clientLogInfo("LoginUserPage.tsx", "checkLogin", "login session has not started");
            initError(
                translations.pages.loginPage.errors.mitidNotOK,
                translations.pages.loginPage.errors.mitidNotOKText
            )
            return;
        }

        setLoading(true);

        try {
            const sessionresponse = await fetch(`/api/login/sessions`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    sessionId: sessionId,
                    country: country,
                },
            });

            if (!sessionresponse.ok) {
                clientLogError("OrderStorageStep3RegisterPage.tsx", "GetSessionInfo", "Session response not ok");
                initError(
                    translations.pages.loginPage.errors.mitidNotOK,
                    translations.pages.loginPage.errors.mitidNotOKText
                );
                return;
            }

            const sessionResult = await sessionresponse.json() as iMitIdSessionResponse;
            if (sessionResult.isLoggedIn) {
                setSessionInfo(sessionResult);
            }
        } catch (error) {
            clientLogError("LoginUserPage.tsx", "checkLogin", "Error", error);
            initError(
                translations.pages.loginPage.errors.loginNotOK,
                translations.pages.loginPage.errors.loginNotOKText
            )
        } finally {
            clientLogInfo("LoginUserPage.tsx", "checkLogin", "finally");
        }
    }

    //STEP 5 - login user
    const onLoginSubmit = async (loginToken: string | undefined) => {
        clientLogInfo("LoginUserPage.tsx", "onLoginSubmit", `Logging in user with login token: ${loginToken}`);
        if (!loginToken || loginToken === "") {
            clientLogInfo("LoginUserPage.tsx", "onLoginSubmit", "Personal login token does not exists");
            initError(
                translations.pages.loginPage.errors.mitidNotOK,
                translations.pages.loginPage.errors.mitidNotOKText
            )
            return;
        }

        setLoading(true);

        try {


            let users = await GetSpaceManagerUsersByToken(loginToken);

            if (!users || users.length === 0 && isFallbackLogin) {
                users = await GetSpaceManagerUsersByIdentityNumber(user.personalNumber);
            }

            clientLogInfo("LoginUserPage.tsx", "onLoginSubmit", "userresponse", users);
            const timespan = new Date().getTime();
            if (users && users.length > 0) {
                let bodyString = JSON.stringify({ sessionId: sessionId, loginToken: loginToken });

                if (isFallbackLogin) {
                    bodyString = JSON.stringify({ sessionId: sessionId, loginToken: loginToken, personalNumber: user.personalNumber });
                }

                const response = await fetch(`/api/user/login?timespan=${timespan}`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Country: country,
                    },
                    body: bodyString,
                });


                if (!response.ok) {
                    clientLogError("LoginUserPage.tsx", "onLoginSubmit", "Network response was not ok");
                    throw new Error("Network response was not ok");
                }

                setRequestSent(true);
                router.push(myPagesLink);
            } else if (!users || users.length === 0) {
                //Show popup with CPR inlog
                if (isFallbackLogin) {
                    initError(
                        translations.pages.loginPage.errors.userNotExists,
                        translations.pages.loginPage.errors.userNotExistsText,
                        translations.general.close
                    )
                } else {
                    setIsFallbackLogin(true);
                    setShowFallbackLogin(true);
                }

                setLoading(false);
                clientLogWarn("LoginUserPage.tsx", "onLoginSubmit", "User not found", `User with login token: ${loginToken}`, "Trying with CPR login");

                return;
            }


        } catch (error) {
            clientLogError("LoginUserPage.tsx", "onLoginSubmit", "Error", error);
            initError(
                translations.pages.loginPage.errors.loginNotOK,
                translations.pages.loginPage.errors.loginNotOKText
            )
        } finally {
            clientLogInfo("LoginUserPage.tsx", "onLoginSubmit", "finally");
        }
    };

    const clearError = async () => {
        setShowError(false);
        setErrorTitle("");
        setErrorText("");
    }

    const clearCPRLogin = async () => {
        setIsFallbackLogin(false);
        //TODO: reload page
    }
    const initError = (title: string = "", message: string = "", buttonText = "") => {
        if (title === "") {
            title = translations.general.errors.e500.title;
        }

        if (message === "") {
            message = translations.general.errors.e500.message;
        }

        if (buttonText === "") {
            buttonText = translations.pages.loginPage.errors.tryAgain;
        }

        setErrorTitle(title);
        setErrorText(message);
        setErrorButtonText(buttonText);
        setLoading(false);
        setShowError(true);
    }

    return (
        <div className="flex flex-col space-y-4">
            <Loader loading={loading} useFullWindow={true} useNoBackground={false} />
            <Modal
                isOpen={showError}
                onClose={clearError}
                closeTranslation={translations.general.closePopup}
            >
                <div>
                    <h2>{errorTitle}</h2>
                    <div>{errorText}</div>
                    <LinkElement
                        title={errorButtonText}
                        isButton={true}
                        onClick={clearError}
                        buttonColorTheme={eButtonColorTheme.DarkContrast}
                        className="w-full mt-6"
                    >
                        {errorButtonText}
                    </LinkElement>

                </div>
            </Modal>
            <Modal
                isOpen={isFallbackLogin && showFallbackLogin}
                onClose={clearCPRLogin}
                closeTranslation={translations.general.closePopup}
            >
                <div>
                    <h2>{fallbackTitle}</h2>
                    <div dangerouslySetInnerHTML={{ __html: fallbackText.markup }}></div>
                    <div className="mb-6">
                        <FormInput
                            placeholder={translations.pages.loginPage.personalIdentificationNumber}
                            type="text"
                            name="uniqueId"
                            label={translations.pages.loginPage.personalIdentificationNumber}
                            className="w-full"
                            onChange={(e) => {
                                if (typeof e === 'string') {
                                    setUniqueId(e);
                                } else {
                                    setUniqueId(e.target.value);
                                }
                            }}
                            onKeyDown={(e) => {
                                if (typeof e === 'string') {
                                    if (e === "Enter") {
                                        handleCPRSubmit(); //SKIPMITID ändra till onLoginSubmit och lägg till uniqueId
                                    }
                                } else if (e.key === "Enter") {
                                    handleCPRSubmit(); //SKIPMITID ändra till onLoginSubmit och lägg till uniqueId
                                }
                            }}
                        />
                    </div>
                    <LinkElement
                        title={translations.general.loginButton}
                        isButton={true}
                        onClick={() => handleCPRSubmit()} //SKIPMITID ändra till onLoginSubmit och lägg till uniqueId
                        buttonColorTheme={eButtonColorTheme.Green}
                        className={`w-full`}
                    >
                        {translations.general.loginButton}
                    </LinkElement>
                    <LinkElement
                        title={translations.general.close}
                        isButton={true}
                        onClick={clearCPRLogin}
                        buttonColorTheme={eButtonColorTheme.DarkContrast}
                        className="w-full mt-6"
                    >
                        {translations.general.close}
                    </LinkElement>

                </div>
            </Modal>

            <div>
                <LinkElement
                    title={translations.general.login}
                    isButton={true}
                    onClick={() => handleSubmit()} //SKIPMITID ändra till onLoginSubmit och lägg till uniqueId
                    buttonColorTheme={eButtonColorTheme.Green}
                    className={`w-full`}
                >
                    {translations.general.loginButton}
                </LinkElement>
            </div>
        </div>
    );
}
