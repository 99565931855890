'use client';
import { iRequestContext } from "@/app/models/contextModels/requestContext";
import { iMyPagesStorageData } from "@/app/models/myPages/myPagesStorageData";
import FormSelect from "../../Forms/FormSelect";
import { iSelectItem } from "@/app/models/componentModels/selectItem";
import { useEffect, useState } from "react";
import Loader from "../../Loader/Loader";
import FormCheckbox from "../../Forms/FormCheckbox";
import LinkElement from "../../General/LinkElement";
import { eLinkSize } from "@/app/models/enum/linkSize";
import { iMyPagesTerminateUnitRequestModel } from "@/app/models/apiModels/requestModels/myPagesTerminateUnitRequestModel";
import { eTarget } from "@/app/models/enum/target";
import { logError, logInfo } from "@/app/helpers/azureBlobLogger";
import { eButtonColorTheme } from "@/app/models/enum/eButtonColorTheme";
import { iFormDictionary } from "@/app/models/formDictionary";
import { iDictionary } from "@/app/models/dictionary";
import { formatDateByCountry, formatDateFromDate } from "@/app/helpers/dateHelper";

interface Props {
  storageUnit: iMyPagesStorageData;
  requestContext: iRequestContext;
  onClose: () => void;
  customerId: string;
}

const getNextMonthDate = (date: Date, increment: boolean = true): Date => {
  let year = date.getFullYear();
  let month = increment ? date.getMonth() + 1 : date.getMonth();
  let lastDay = new Date(year, month + 1, 0).getDate();

  if (month === 12) {
    year++;
    month = 0;
  }

  const newDate = new Date(year, month, lastDay);

  return newDate;
};

export default function TerminateStorageUnit({
  storageUnit,
  requestContext,
  onClose,
  customerId,
}: Props) {
  interface FormData {
    terminationDate: string;
    termsAndConditions: boolean;
  }

  const [availableDates, setAvailableDates] = useState<iSelectItem[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [errorDetected, setErrorDetected] = useState<boolean>(false);
  const [errorTitle, setErrorTitle] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [inputErrors, setInputErrors] = useState<iDictionary>({});
  const [formValues, setFormValues] = useState<iFormDictionary>();
  const [isValid, setIsValid] = useState<boolean>(false);
  const [isTerminationDateDirty, setIsTerminationDateDirty] = useState<boolean>(false);
  const [isTermsAndConditionsDirty, setIsTermsAndConditionsDirty] = useState<boolean>(false);

  const initError = (title: string = "", message: string = "") => {
    if (title === "") {
      title = requestContext.translations.general.errors.e500.title;
    }

    if (message === "") {
      message = requestContext.translations.general.errors.e500.message;
    }

    setErrorTitle(title);
    setErrorMessage(message);
    setErrorDetected(true);
    setLoading(false);
  };

  const clearError = () => {
    //TODO: Fix this later
    const scrollY = document.body.style.top;
    document.body.style.position = "";
    document.body.style.top = "";
    document.body.style.width = ""; // Reset width
    window.scrollTo(0, parseInt(scrollY || "0") * -1);

    setErrorDetected(false);
    setErrorTitle("");
    setErrorMessage("");
  };

  useEffect(() => {
    const getAvailableDates = () => {
      try {
        logInfo(
          "TerminateStorageUnit.tsx",
          "getAvailableDates",
          "Fetching available dates"
        );
        const dateList: Date[] = [];
        let date = new Date();
        const day = date.getDate();

        if (day < 16) {
          date = getNextMonthDate(date, false);
          dateList.push(date);
        }

        for (let i = 1; i <= 3; i++) {
          date = getNextMonthDate(date);
          dateList.push(date);
        }

        // const formatDate = (date: Date) => {
        //   const year = date.getFullYear();
        //   const month = String(date.getMonth() + 1).padStart(2, "0");
        //   const day = String(date.getDate()).padStart(2, "0");
        //   return `${year}-${month}-${day}`;
        // };

        setAvailableDates(
          dateList.map((date) => ({
            value: formatDateFromDate(date),
            label: formatDateByCountry(date, requestContext.country),
          }))
        );

        const newUserValues: iFormDictionary = {};
        newUserValues["termsAndConditions"] = false;
        setFormValues(newUserValues);

        //TODO: Handle success (e.g., show a success message)
      } catch (error) {
        //TODO: Handle error (e.g., show an error message)
        logError("TerminateStorageUnit.tsx", "getAvailableDates", error);
      } finally {
        setLoading(false);
      }
    };

    getAvailableDates();
  }, []);

  const onSubmit = async () => {
    setLoading(true);

    if (!formValues || !formValues.terminationDate) {
      return;
    }

    try {
      logInfo(
        "TerminateStorageUnit.tsx",
        "onSubmit",
        "Terminating storage unit"
      );
      const requestBody: iMyPagesTerminateUnitRequestModel = {
        contractId: storageUnit.unitData.unitSubContractId,
        noticeLeaveDate: formValues.terminationDate as string,
        customerId: customerId,
      };

      const response = await fetch("/api/unit/terminateUnit", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Country: requestContext.country,
        },
        body: JSON.stringify(requestBody),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      onClose();
    } catch (error) {
      initError(
        requestContext.translations.pages.changeStorage.errorTitle,
        requestContext.translations.pages.changeStorage.errorMessage
      );
      logError("TerminateStorageUnit.tsx", "onSubmit", error);
    } finally {
      setLoading(false);
    }
  };

  const onValueChange = (name: string, value: string | boolean, altValue?: any) => {
    if (name === "terminationDate") {
      setIsTerminationDateDirty(true);
    } else if (name === "termsAndConditions") {
      setIsTermsAndConditionsDirty(true);
    }
    setFormValues({ ...formValues, [name]: value });
  };

  useEffect(() => {
    let isValid = true;
    if (formValues) {
      let newInputErrors: iDictionary = {};

      const terminationDate = formValues.terminationDate as string;
      const termsAndConditions = formValues.termsAndConditions as boolean;

      if (!terminationDate) {
        isValid = false;
        if (isTerminationDateDirty) {
          newInputErrors = { ...newInputErrors, ["terminationDate"]: [requestContext.translations.general.errors.required] };
        }
      }

      if (!termsAndConditions) {
        isValid = false;
        if (isTermsAndConditionsDirty) {
          newInputErrors = { ...newInputErrors, ["termsAndConditions"]: [requestContext.translations.general.errors.required] };
        }
      }

      setInputErrors(newInputErrors);
    }

    setIsValid(isValid);
  }, [formValues]);



  return (
    <div className="flex flex-col gap-4">
      {errorDetected ? (
        <div>
          <h2>{errorTitle}</h2>
          <div className="mb-8">{errorMessage}</div>
          <LinkElement
            title={requestContext.translations.general.close}
            isButton={true}
            onClick={clearError}
            buttonColorTheme={eButtonColorTheme.DarkContrast}
            className="w-full mt-6"
          >
            {requestContext.translations.general.close}
          </LinkElement>
        </div>
      ) : (
        <>
          <Loader loading={loading} useFullWindow={true} />
          <h2>{requestContext.translations.general.terminateStorage.title}</h2>
          <div>
            <FormSelect
              name="terminationDate"
              label={
                requestContext.translations.general.terminateStorage
                  .whichDayToTerminate
              }
              errors={inputErrors}
              options={availableDates}
              placeholder={requestContext.translations.general.chooseDate}
              useEmptyDefault={true}
              className="mb-6"
              isMandatory={true}
              onValueChange={onValueChange}
            />
            <FormCheckbox
              name="termsAndConditions"
              errors={inputErrors}
              isMandatory={true}
              onValueChange={onValueChange}
            >
              {
                requestContext.translations.general.terminateStorage
                  .termsAndConditions
              }
              <LinkElement
                href={
                  requestContext.configurations.pages
                    .terminateStorageTermsAndConditionsPage.url
                }
                title={requestContext.translations.general.link}
                className="ml-1"
                linkSize={eLinkSize.Large}
                target={eTarget.Blank}
              >
                {requestContext.translations.general.link}
              </LinkElement>
            </FormCheckbox>
          </div>
          <div className="flex flex-row gap-4 mt-10">
            <LinkElement
              title={requestContext.translations.general.close}
              isButton={true}
              onClick={onClose}
              buttonColorTheme={eButtonColorTheme.DarkContrast}
              className="w-full"
            >
              {requestContext.translations.general.close}
            </LinkElement>

            <LinkElement
              title={requestContext.translations.general.send}
              isButton={true}
              onClick={onSubmit}
              disabled={!isValid}
              buttonColorTheme={eButtonColorTheme.Green}
              className="w-full"
            >
              {requestContext.translations.general.send}
            </LinkElement>
          </div>
        </>
      )}
    </div>
  );
}
