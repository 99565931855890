'use client';
import { iRequestContext } from '@/app/models/contextModels/requestContext';
import styles from './newsblock.module.scss';
import { logError } from '@/app/helpers/azureBlobLogger';
import { iNewsBlock } from '@/app/models/blockTypes/newsBlock';
import { iBasePage } from '@/app/models/pageTypes/basePage';

interface Props {
    block: iNewsBlock;
    requestContext: iRequestContext;
}


export default function NewsBlock({ block, requestContext }: Props) {
    try {
        if (!block || !block.properties || !block.properties.linkedContent) {
            return (
                <></>
            );
        }

        const linkedContent = block?.properties?.linkedContent[0] as iBasePage;

        return (
            <div className="p-6 xl:p-0">
                <div className={`w-full GetBlockWrapperClasses(block)`} >
                    <div className={styles.newsBlock}>
                        <a
                            href={linkedContent.route.path}
                            className={`${styles.contentLink} inline-block w-full`}
                            title={`${requestContext.translations.general.readMore} ${requestContext.translations.general.about} "${block.properties.text}"`}
                            aria-label={`${requestContext.translations.general.readMore} ${requestContext.translations.general.about} "${block.properties.text}"`}
                        >
                            <span className={`${styles.newsNews} inline mr-2`}>{requestContext.translations.general.new}</span>
                            <span className={`${styles.newsText} inline`}>{block.properties.text}</span>
                            <span className={`${styles.newsReadMore}  inline ml-2`}>{requestContext.translations.general.readMore}</span>
                        </a>
                    </div >
                </div>
            </div>
        );
    } catch (error) {
        logError("NewsBlock.tsx", 'Error when generating NewsBlock', `current url: ${requestContext.url}`, 'Error', error);
    }
}
