"use client";

import { iMyPagesUserOrgData } from "@/app/models/myPages/myPagesUserOrgData";
import { iMyPagesUserPersonData } from "@/app/models/myPages/myPagesUserPersonData";
import React, { useEffect, useState } from "react";
import FormInput from "../Forms/FormInput";
import Modal from "../Modal/Modal";
import GDPR from "../GDPR/GDPR";
import Loader from "../Loader/Loader";
import { iRequestContext } from "@/app/models/contextModels/requestContext";
import LinkElement from "../General/LinkElement";
import { eChevronColor } from "@/app/models/enum/chevronColor";
import { eChevronPosition } from "@/app/models/enum/chevronPosition";
import { eLinkSize } from "@/app/models/enum/linkSize";
import { eTextColor } from "@/app/models/enum/textColor";
import { iMyPagesUser } from "@/app/models/myPages/myPagesUser";
import {
  clientLogError,
  clientLogInfo,
} from "@/app/helpers/clientLoggingHelper";
import { iDictionary } from '@/app/models/dictionary';
import { eButtonColorTheme } from "@/app/models/enum/eButtonColorTheme";

interface Props {
  profile: iMyPagesUser;
  country: string;
  requestContext: iRequestContext;
  onUpdateProfile: (updatedProfile: iMyPagesUser) => void;
  payByInvoice: boolean;
}

export default function Profile({
  profile,
  requestContext,
  country,
  onUpdateProfile,
  payByInvoice,
}: Props) {
  const [loading, setLoading] = useState<boolean>(false);
  const [user, setUser] = useState<iMyPagesUserPersonData | iMyPagesUserOrgData | undefined>();
  const [isPerson, setIsPerson] = useState<boolean>();
  const [inputErrors, setInputErrors] = useState<iDictionary>({});
  const [formValues, setFormValues] = useState<iDictionary>();
  const [showSaveModal, setShowSaveModal] = useState<boolean>(false);
  const [showValidationErrorModal, setShowValidationErrorModal] = useState<boolean>(false);
  const [showErrorModal, setShowErrorModal] = useState<boolean>(false);
  const [phoneCountryCode, setPhoneCountryCode] = useState<string>("");
  const [isValidationActive, setIsValidationActive] = useState<boolean>(false);

  // Update user state when profile prop changes
  useEffect(() => {
    setUser(profile.userDataPerson || profile.userDataOrganisation);
  }, [profile]);

  // Update isPerson state when user state changes
  useEffect(() => {
    setIsPerson(getIsPerson(user));
  }, [user]);

  // Update formValues state when user or isPerson state changes
  useEffect(() => {
    if (user) {
      const newUserValues: iDictionary = {};
      for (const key in user) {
        if (user.hasOwnProperty(key)) {
          newUserValues[key] = (user as any)[key]; // Type assertion to resolve the error
        }
      }
      setFormValues(newUserValues);
    }
  }, [user, isPerson]);

  const getIsPerson = (user: iMyPagesUserPersonData | iMyPagesUserOrgData | undefined): boolean => {
    const isPerson = profile.userDataPerson ? true : false;
    return isPerson;
  };
  const updatePersonProfile = async (data: iMyPagesUserPersonData) => {
    try {
      clientLogInfo("Profile.tsx", "Updating person profile", data);
      const response = await fetch("/api/user/updatePersonProfile", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Country: country,
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        clientLogError("Profile.tsx", "Network response was not ok", response);
        throw new Error("Network response was not ok");
      }
    } catch (error) {
      clientLogError("Profile.tsx", "Error updating person profile", "data", data, error);
    }
  };

  const updateOrgProfile = async (data: iMyPagesUserOrgData) => {
    try {
      clientLogInfo("Profile.tsx", "Updating org profile", data);
      const response = await fetch("/api/user/updateOrgProfile", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Country: country,
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        clientLogError("Profile.tsx", "Network response was not ok saving Org Profile", "Data", data, response);
        throw new Error("Network response was not ok");
      }
    } catch (error) {
      clientLogError("Profile.tsx", "Error updating org profile", "data", data, error);
    }
  };

  const onSubmit = async () => {
    clientLogInfo("Profile.tsx", "Submitting form", formValues);
    setLoading(true);
    try {

      if (!formValues) {
        throw new Error("No form values");
      }

      if (!validateForm()) {
        setIsValidationActive(true);
        setShowValidationErrorModal(true);

        return;
      }

      if (isPerson) {

        let personUser: iMyPagesUserPersonData = { ...user } as iMyPagesUserPersonData;
        for (const key in personUser) {
          if (personUser.hasOwnProperty(key)) {
            (personUser as any)[key] = formValues[key]; // Type assertion to resolve the error
          }
        }

        clientLogInfo("Profile.tsx", "Updating person profile", personUser);

        await updatePersonProfile(personUser);
        //TODO: Check if this is correct
        onUpdateProfile({ ...profile, userDataPerson: personUser });
        setShowSaveModal(true);
      } else {

        let orgUser: iMyPagesUserOrgData = { ...user } as iMyPagesUserOrgData;
        for (const key in orgUser) {
          if (orgUser.hasOwnProperty(key)) {
            (orgUser as any)[key] = formValues[key]; // Type assertion to resolve the error
          }
        }
        clientLogInfo("Profile.tsx", "Updating org profile", orgUser);


        await updateOrgProfile(orgUser);
        onUpdateProfile({ ...profile, userDataOrganisation: orgUser });
        setShowSaveModal(true);
      }
      //TODO: Handle success (e.g., show a success message)
    } catch (error) {
      clientLogError("Profile.tsx", "Error updating profile", "formValues", formValues, error);
      setShowErrorModal(true);
      //TODO: Handle error (e.g., show an error message)
    } finally {
      setLoading(false);
    }
  };

  const validateForm = (): boolean => {
    let isValid = true;
    let newInputErrors: iDictionary = {};

    if (!formValues) {
      isValid = false;
      return isValid;
    }

    const forname = formValues["forename"] as string;
    if (!forname || forname === "") {
      isValid = false;
      newInputErrors = { ...newInputErrors, ["forename"]: [requestContext.translations.pages?.order.form.fornameError] };
    }

    const surname = formValues["surname"] as string;
    if (!surname || surname === "") {
      isValid = false;
      newInputErrors = { ...newInputErrors, ["surname"]: [requestContext.translations.pages?.order.form.surnameError] };
    }

    const streetAddress = formValues["streetAddress"] as string;
    if (!streetAddress || streetAddress === "") {
      isValid = false;
      newInputErrors = { ...newInputErrors, ["streetAddress"]: [requestContext.translations.pages?.order.form.streetAddressError] };
    }

    const zipCode = formValues["zipCode"] as string;
    if (!zipCode || zipCode === "") {
      isValid = false;
      newInputErrors = { ...newInputErrors, ["zipCode"]: [requestContext.translations.pages?.order.form.postalCodeError] };
    }

    const city = formValues["city"] as string;
    if (!city || city === "") {
      isValid = false;
      newInputErrors = { ...newInputErrors, ["city"]: [requestContext.translations.pages?.order.form.cityError] };
    }

    if (payByInvoice) {
      const invoiceEmail = formValues["invoiceEmail"] as string;
      if (!invoiceEmail || invoiceEmail === "") {
        isValid = false;
        newInputErrors = { ...newInputErrors, ["invoiceEmail"]: [requestContext.translations.pages?.order.form.invoiceEmailError] };
      } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(invoiceEmail)) {
        isValid = false;
        newInputErrors = { ...newInputErrors, ["invoiceEmail"]: [requestContext.translations.pages?.order.form.invoiceEmailValidError] };

      }
    }

    const email = formValues["email"] as string;
    if (!email || email === "") {
      isValid = false;
      newInputErrors = { ...newInputErrors, ["email"]: [requestContext.translations.pages?.order.form.emailLengthError] };
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
      isValid = false;
      newInputErrors = { ...newInputErrors, ["email"]: [requestContext.translations.pages?.order.form.emailValidError] };
    }

    if (!formValues["phoneNumber"] || formValues["phoneNumber"] === "") {
      isValid = false;
      newInputErrors = { ...newInputErrors, ["phoneNumber"]: [requestContext.translations.pages?.order.form.phoneNumberEmptyError] };
    } else {
      const phoneNumber = formValues["phoneNumber"] as string;
      const countryCodeRegex = new RegExp(`^\\+${phoneCountryCode}`);
      if (!countryCodeRegex.test(phoneNumber)) {
        isValid = false;

        if (newInputErrors["phoneNumber"]) {
          (newInputErrors["phoneNumber"] as string[]).push(`${requestContext.translations.pages?.order.form.phoneNumberCountryCodeError} (+${phoneCountryCode})`);
        } else {
          newInputErrors = {
            ...newInputErrors, ["phoneNumber"]: [`${requestContext.translations.pages?.order.form.phoneNumberCountryCodeError} (+${phoneCountryCode})`]
          }
        }
      }

      const phoneNumberWithoutCountryCode = phoneNumber.replace(`+${phoneCountryCode}`, '');
      if (!/^\d{5,15}$/.test(phoneNumberWithoutCountryCode) && phoneNumber.length !== 0) {
        isValid = false;
        if (newInputErrors["phoneNumber"]) {
          (newInputErrors["phoneNumber"] as string[]).push(requestContext.translations.pages?.order.form.phoneNumberNumberError);
        } else {
          newInputErrors = {
            ...newInputErrors, ["phoneNumber"]: [requestContext.translations.pages?.order.form.phoneNumberNumberError]
          }
        }
      }

      if (phoneNumber.length < 5) {
        isValid = false;

        if (newInputErrors["phoneNumber"]) {
          (newInputErrors["phoneNumber"] as string[]).push(requestContext.translations.pages?.order.form.phoneNumberTooShortError);
        } else {
          newInputErrors = {
            ...newInputErrors, ["phoneNumber"]: [requestContext.translations.pages?.order.form.phoneNumberTooShortError]
          }
        }
      }

      if (phoneNumber.length > 15) {
        isValid = false;

        if (newInputErrors["phoneNumber"]) {
          (newInputErrors["phoneNumber"] as string[]).push(requestContext.translations.pages?.order.form.phoneNumberTooLongError);
        } else {
          newInputErrors = {
            ...newInputErrors, ["phoneNumber"]: [requestContext.translations.pages?.order.form.phoneNumberTooLongError]
          }
        }
      }
    }

    setInputErrors(newInputErrors);

    return isValid;
  }


  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleCloseSaveModal = () => {
    setShowSaveModal(false);
  };

  const handleCloseErrorModal = () => {
    setShowSaveModal(false);
  };

  const handleCloseValidationErrorModal = () => {
    setShowValidationErrorModal(false);
  };

  const onPhoneMount = (name: string, value: string, countryData?: any) => {
    if (countryData && countryData.dialCode) {
      setPhoneCountryCode(countryData.dialCode);
    }
  }

  const onValueChange = (name: string, value: string, altValue?: any) => {
    setFormValues({ ...formValues, [name]: value });

    if (name === "phoneNumber" && altValue && altValue.dialCode) {
      setPhoneCountryCode(altValue.dialCode);
    }
  };

  const onError = (name: string, error: string) => {
    setInputErrors({ ...inputErrors, [name]: error });
  }

  useEffect(() => {
    if (isValidationActive) {
      validateForm();
    }
  }, [formValues]);

  const transformFormValues = (values: iDictionary | undefined): iDictionary => {
    const transformedValues: iDictionary = {};
    if (values) {
      for (const key in values) {
        if (values.hasOwnProperty(key)) {
          transformedValues[key] = values[key] === null ? "" : values[key];
        }
      }
    }
    return transformedValues;
  };

  const transformedFormValues = transformFormValues(formValues);

  return (
    <div className="rounded-3xl bg-white p-10 mb-10">
      <Loader loading={loading} useFullWindow={true} />

      <h2>{requestContext.translations.pages.myPagesPage.myProfile}</h2>
      {formValues && (
        <>
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 mb-10">
            {!isPerson && (
              <>
                <FormInput
                  name="orgName"
                  value={formValues["orgName"] as string}
                  label={requestContext.translations.general.orgName}
                  onValueChange={onValueChange}
                  isMandatory={!isPerson}
                  disabled={true}
                  className="col-span-1"
                  errors={inputErrors}
                />

                <FormInput
                  name="orgNumber"
                  value={formValues["orgNumber"] as string}
                  label={requestContext.translations.general.orgNumber}
                  onValueChange={onValueChange}
                  isMandatory={!isPerson}
                  disabled={true}
                  className="col-span-1"
                  errors={inputErrors}
                />
              </>
            )}

            <FormInput
              name="forename"
              value={formValues["forename"] as string}
              label={requestContext.translations.general.forename}
              onValueChange={onValueChange}
              className="col-span-1"
              errors={inputErrors}
              />

            <FormInput
              name="surname"
              value={formValues["surname"] as string}
              label={requestContext.translations.general.surname}
              onValueChange={onValueChange}
              className="col-span-1"
              errors={inputErrors}
              />

            <FormInput
              name="streetAddress"
              value={formValues["streetAddress"] as string}
              label={requestContext.translations.general.streetAddress}
              onValueChange={onValueChange}
              className="col-span-1"
              errors={inputErrors}
              />

            <FormInput
              name="zipCode"
              value={formValues["zipCode"] as string}
              label={requestContext.translations.general.zipCode}
              onValueChange={onValueChange}
              className="col-span-1"
              errors={inputErrors}
              />

            <FormInput
              name="city"
              value={formValues["city"] as string}
              label={requestContext.translations.general.city}
              onValueChange={onValueChange}
              className="col-span-1"
              errors={inputErrors}
              />

            <FormInput
              name="email"
              value={formValues["email"] as string}
              label={requestContext.translations.general.email}
              onValueChange={onValueChange}
              type="email"
              className="col-span-1"
              errors={inputErrors}
              />

            <FormInput
              name="phoneNumber"
              value={formValues["phoneNumber"] as string}
              label={requestContext.translations.general.phoneNumber}
              onValueChange={onValueChange}
              className="col-span-1"
              type="tel"
              country={country}
              onMount={onPhoneMount}
              errors={inputErrors}
              />

            {payByInvoice && (
              <>
                <FormInput
                  name="invoiceEmail"
                  value={transformedFormValues["invoiceEmail"] as string}
                  label={requestContext.translations.general.invoiceEmail}
                  onValueChange={onValueChange}
                  className="col-span-1"
                  type="email"
                  errors={inputErrors}
                />
                <FormInput
                  name="invoiceReference"
                  value={transformedFormValues["invoiceReference"] as string}
                  label={requestContext.translations.general.invoiceReference}
                  onValueChange={onValueChange}
                  className="col-span-1"
                />
              </>
            )}
          </div>

          <div className="flex flex-col md:flex-row items-end">
            <LinkElement
              title={requestContext.translations.general.updateProfile}
              chevronColor={eChevronColor.Green}
              chevronPosition={eChevronPosition.None}
              isButton={true}
              onClick={onSubmit}
            >
              {requestContext.translations.general.updateProfile}
            </LinkElement>
            <div className="flex-grow"></div>
            <div className="w-full md:w-auto flex justify-end">
              <LinkElement
                title={requestContext.translations.general.gdpr.title}
                chevronColor={eChevronColor.Green}
                chevronPosition={eChevronPosition.Right}
                linkSize={eLinkSize.Medium}
                styleType={eTextColor.Black}
                onClick={handleOpenModal}
              >
                {requestContext.translations.general.gdpr.title}
              </LinkElement>
            </div>
          </div>
        </>
      )}

      <Modal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        closeTranslation={requestContext.translations.general.closePopup}
        className="w-500-responsive"
      >
        <GDPR
          country={country}
          gdprText={
            requestContext.configurations.myPagesConfigurations.gdprText
          }
          translations={requestContext.translations}
          onClose={handleCloseModal}
        />
      </Modal>

      <Modal
        isOpen={showSaveModal}
        onClose={handleCloseSaveModal}
        closeTranslation={requestContext.translations.general.closePopup}
        className="w-500-responsive"
      >
        <div>
          <h2>{requestContext.translations.pages.myPagesPage.profileSavedTitle}</h2>
          <div>{requestContext.translations.pages.myPagesPage.profileSavedText}</div>
          <LinkElement
            title={requestContext.translations.general.close}
            isButton={true}
            onClick={handleCloseSaveModal}
            buttonColorTheme={eButtonColorTheme.Green}
            className="w-full mt-6"
          >
            {requestContext.translations.general.close}
          </LinkElement>

        </div>
      </Modal>

      <Modal
        isOpen={showErrorModal}
        onClose={handleCloseErrorModal}
        closeTranslation={requestContext.translations.general.closePopup}
        className="w-500-responsive"
      >
        <div>
          <h2>{requestContext.translations.pages.myPagesPage.profileNotSavedTitle}</h2>
          <div>{requestContext.translations.pages.myPagesPage.profileNotSavedText}</div>
          <LinkElement
            title={requestContext.translations.general.close}
            isButton={true}
            onClick={handleCloseErrorModal}
            buttonColorTheme={eButtonColorTheme.Green}
            className="w-full mt-6"
          >
            {requestContext.translations.general.close}
          </LinkElement>

        </div>
      </Modal>

      <Modal
        isOpen={showValidationErrorModal}
        onClose={handleCloseValidationErrorModal}
        closeTranslation={requestContext.translations.general.closePopup}
        className="w-500-responsive"
      >
        <div>
          <h2>{requestContext.translations.pages?.order.form.validationErrorTitle}</h2>
          <div>
            <ul>
              {Object.keys(inputErrors).map((key) => {
                return (
                  <React.Fragment key={key}>
                    {(inputErrors[key] as string[]).map((error, index) => {
                      return (
                        <li key={index}>{error}</li>
                      );
                    })}
                  </React.Fragment>
                );
              })}
            </ul>
          </div>
          <LinkElement
            title={requestContext.translations.general.close}
            isButton={true}
            onClick={handleCloseValidationErrorModal}
            buttonColorTheme={eButtonColorTheme.Green}
            className="w-full mt-6"
          >
            {requestContext.translations.general.close}
          </LinkElement>

        </div>
      </Modal>
    </div>
  );
}
