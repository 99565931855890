import { iRegionalAvailability } from "@/app/models/regionalAvailability";

interface Props {
    availability: iRegionalAvailability;
    checkLogin?:boolean;
    checkPayment?:boolean;
    checkAvailability?:boolean;
}


export default function FeatureOffline({ availability, checkLogin = false, checkPayment = false, checkAvailability = false }: Props) {
    const shouldCheckAll = !checkLogin && !checkPayment && !checkAvailability;

    const showAvailabilityError = (shouldCheckAll || checkAvailability) && !availability.isAvailable;
    const showLoginError = (shouldCheckAll || checkLogin) && !availability.isLoginEnabled;
    const showPaymentError = (shouldCheckAll || checkPayment) && !availability.isPaymentEnabled;

    const allFeaturesIsAvailable = !showAvailabilityError && !showLoginError && !showPaymentError;

    return (
        <>
            {!allFeaturesIsAvailable && (
                <div className="bg-gs-orange p-6 rounded-3xl text-white">
                    {!availability.isAvailable && (
                        <>
                            <h3>
                                {availability.availabilityErrorTitle}
                            </h3>
                            <div className="mb-4">
                                {availability.availabilityErrorText}
                            </div>
                        </>
                    )}

                    {!availability.isLoginEnabled && (
                        <>
                            <h3>
                                {availability.loginErrorTitle}
                            </h3>
                            <div className="mb-4">
                                {availability.loginErrorText}
                            </div>
                        </>
                    )}

                    {!availability.isPaymentEnabled && (
                        <>
                            <h3>
                                {availability.paymentErrorTitle}
                            </h3>
                            <div className="mb-4">
                                {availability.paymentErrorText}
                            </div>
                        </>
                    )}
                </div>
            )}
        </>
    );
}
