'use client';
import { renderRegisteredBlock } from '@/app/content/blocks/block.registry';
import { logError } from '@/app/helpers/azureBlobLogger';
import { clientLogError, clientLogInfo } from '@/app/helpers/clientLoggingHelper';
import { iGetContentListRequestModel } from '@/app/models/apiModels/requestModels/getContentListRequestModel';
import { iBaseBlock } from '@/app/models/blockTypes/baseBlock';
import { iBaseBlockContainer } from '@/app/models/blockTypes/baseBlockContainer';
import { iRequestContext } from '@/app/models/contextModels/requestContext';
import { GetContentListAsync } from '@/services/umbraco/umbraco.content';
import React, { useEffect, useState } from 'react';
import { renderRegisteredClientBlock } from '../clientblock.registry';
import Loader from '@/app/components/Loader/Loader';

interface Props {
  blockListItems: iBaseBlockContainer[];
  columns?: number;
  requestContext: iRequestContext;
  previewMode?: boolean;
  className?: string;
  isCompact?: boolean;
}

export default function ClientBlockList({ blockListItems, columns, requestContext, previewMode = false, className = "", isCompact = false }: Props) {
  const [isLoading, setIsLoading] = useState(true);
  const [fetchedBlocks, setFetchedBlocks] = useState<iBaseBlock[]>();

  const columnCount = columns || 1;
  const gridColumnClass = `md:grid-cols-${columnCount}`;
  const fullCssClasses = `block-list grid grid-cols-1 ${isCompact ? "gap-y-4 xl:gap-x-6 lg:gap-y-6" : "gap-y-4 xl:gap-x-10 lg:gap-y-20"}  align-center ${gridColumnClass} ${className}`;

  const fetchBlocks = async () => {
    setIsLoading(true);

    try {
      const safeBlockListItems = Array.isArray(blockListItems) ? blockListItems : [];
      const blockIdsList = safeBlockListItems
        .filter((block) => block.content?.properties?.block != null)
        .map((block) => block.content.properties.block.id);
      const requestModel: iGetContentListRequestModel = {
        contentIds: blockIdsList ?? [],
        language: requestContext.language,
        previewMode: previewMode,
      };

      const response = await fetch('/api/content/getContentList', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestModel),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      const tempFetchedBlocks = data as iBaseBlock[];
      setFetchedBlocks(tempFetchedBlocks);
    }
    catch (error) {
      clientLogError("ClientBlockList.tsx", "fetchItems", error);

    }
    finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    fetchBlocks();
  }, []);

  return (
    <div className={fullCssClasses}>
      {isLoading ? (
        <Loader loading={isLoading} useFullWindow={false} useNoBackground={true} />
      ) : (
        <>
          {fetchedBlocks && fetchedBlocks.map((block: iBaseBlock, index: number) => (
            <React.Fragment key={index}>
              {renderRegisteredClientBlock(block, requestContext)}
            </React.Fragment>
          ))}
        </>
      )}


    </div>
  );
}
