import { iBaseBlock, iBaseBlockProperties } from '@/app/models/blockTypes/baseBlock';
import FAQBlock from './FAQBlock/FAQBlock';
import HeroBlock from './HeroBlock/HeroBlock';
import CampaignBlock from './CampaignBlock/CampaignBlock';
import { iBaseBackgroundBlock } from '@/app/models/blockTypes/baseBackgroundBlock';
import FAQGroupBlock from './FAQGroupBlock/FAQGroupBlock';
import IFrameBlock from './IFrameBlock/IFrameBlock';
import ImageBlock from './ImageBlock/ImageBlock';
import BlockAreaBlock from './BlockAreaBlock/BlockAreaBlock';
import ContentColumnsBlock from './ContentColumnsBlock/ContentColumnsBlock';
import SmallTextLinkBlock from './SmallTextLinkBlock/SmallTextLinkBlock';
import SliderBlockSmallTextLinkBlock from './SliderBlockSmallTextLinkBlock/SliderBlockSmallTextLinkBlock';
import StorageSizesBlock from './StorageSizesBlock/StorageSizesBlock';
import WysiwygBlock from './WysiwygBlock/WysiwygBlock';
import { iRequestContext } from '@/app/models/contextModels/requestContext';
import VideoBlock from './VideoBlock/VideoBlock';
import CardsCollectionBlock from './CardsCollectionBlock/CardsCollectionBlock';
import { iHeroBlock } from '@/app/models/blockTypes/heroBlock';
import { iCampaignBlock } from '@/app/models/blockTypes/campaignBlock';
import { iFAQBlock } from '@/app/models/blockTypes/faqBlock';
import { iFAQGroupBlock } from '@/app/models/blockTypes/faqGroupBlock';
import { iIFrameBlock } from '@/app/models/blockTypes/iframeBlock';
import { iImageBlock } from '@/app/models/blockTypes/imageBlock';
import { iBlockAreaBlock } from '@/app/models/blockTypes/blockAreaBlock';
import { iContentColumnsBlock } from '@/app/models/blockTypes/contentColumnsBlock';
import { iSmallTextLinkBlock } from '@/app/models/blockTypes/smallTextLinkBlock';
import { iSliderBlockSmallTextLinkBlock } from '@/app/models/blockTypes/sliderBlockSmallTextLinkBlock';
import { iStorageSizesBlock } from '@/app/models/blockTypes/storageSizesBlock';
import { iWysiwygBlock } from '@/app/models/blockTypes/wysiwygBlock';
import { iVideoBlock } from '@/app/models/blockTypes/videoBlock';
import { iCardsCollectionBlock } from '@/app/models/blockTypes/cardsCollectionBlock';
import TrustPilotBlock from './TrustPilotBlock/TrustPilotBlock';
import { iTrustPilotBlock } from '@/app/models/blockTypes/trustPilotBlock';
import { logError } from '@/app/helpers/azureBlobLogger';
import { iNewsBlock } from '@/app/models/blockTypes/newsBlock';
import NewsBlock from './NewsBlock/NewsBlock';
import FAQGroupClientBlock from './FAQGroupClientBlock/FAQGroupClientBlock';
import BlockAreaClientBlock from './BlockAreaClientBlock/BlockAreaClientBlock';

export interface ResolvedBlockContent<T> {
    content: T & Partial<iBaseBlock>;
}


export function renderRegisteredClientBlock(block: iBaseBlock, requestContext: iRequestContext) {
    if (!block) {
        return;
    }

    const contentType = block.contentType;

    const model: ResolvedBlockContent<iBaseBlock | iBaseBackgroundBlock> = {
        content: block,
    };

    return renderClientBlockByContentType(contentType, model, requestContext);
}

//Register all blocks here
function renderClientBlockByContentType(contentType: string, model: ResolvedBlockContent<iBaseBlock>, requestContext: iRequestContext) {
    switch (contentType) {
        // case 'globalHeroBlock':
        //     return <HeroBlock block={model.content as iHeroBlock} requestContext={requestContext} />;
        case 'globalCampaignBlock':
            return <CampaignBlock block={model.content as iCampaignBlock} requestContext={requestContext} />;
        case 'globalNewsBlock':
            return <NewsBlock block={model.content as iNewsBlock} requestContext={requestContext} />;
        case 'globalFaqBlock':
            return <FAQBlock block={model.content as iFAQBlock} currentUrl={requestContext.url} translations={requestContext.translations} />;
        case 'globalFaqGroupBlock':
            return <FAQGroupClientBlock block={model.content as iFAQGroupBlock} requestContext={requestContext} />;
        case 'globalIFrameBlock':
            return <IFrameBlock block={model.content as iIFrameBlock} requestContext={requestContext} />;
        // case 'globalImageBlock':
        //     return <ImageBlock block={model.content as iImageBlock} requestContext={requestContext} />;
        case 'globalBlockAreaBlock':
            return <BlockAreaClientBlock block={model.content as iBlockAreaBlock} requestContext={requestContext} />;
        case 'globalContentColumnsBlock':
            return <ContentColumnsBlock block={model.content as iContentColumnsBlock} requestContext={requestContext} />;
        case 'globalSmallTextLinkBlock':
            return <SmallTextLinkBlock block={model.content as iSmallTextLinkBlock} requestContext={requestContext} />;
        // case 'globalSliderBlockSmallTextLinkBlock':
        //     return <SliderBlockSmallTextLinkBlock block={model.content as iSliderBlockSmallTextLinkBlock} requestContext={requestContext} />;
        // case 'globalStorageSizesBlock':
        //     return <StorageSizesBlock block={model.content as iStorageSizesBlock} requestContext={requestContext} />;
        case 'globalWysiwygBlock':
            return <WysiwygBlock block={model.content as iWysiwygBlock} requestContext={requestContext} />;
        case 'globalVideoBlock':
            return <VideoBlock block={model.content as iVideoBlock} translations={requestContext.translations} />;
        // case 'globalCardCollectionBlock':
        //     return <CardsCollectionBlock block={model.content as iCardsCollectionBlock} requestContext={requestContext} />;
        case 'globalTrustPilotBlock':
            return <TrustPilotBlock block={model.content as iTrustPilotBlock} requestContext={requestContext} />;
        default:
            logError(`Could not render CLIENT block with content type: "${contentType}" and name: "${model.content.name}" on page "${requestContext.url}"`);
            return <></>;
    }
}