'use client';
import { iBlockAreaBlock } from '@/app/models/blockTypes/blockAreaBlock';
import { iRequestContext } from '@/app/models/contextModels/requestContext';
import { GetBlockWrapperClasses } from '@/app/helpers/clientUtilityHelper';
import { logError, logInfo } from '@/app/helpers/azureBlobLogger';
import ClientBlockList from '../ClientBlockList/ClientBlockList';

interface Props {
    block: iBlockAreaBlock;
    requestContext: iRequestContext;
}


export default function BlockAreaClientBlock({ block, requestContext }: Props) {
    try {
        logInfo("BlockAreaBlock.tsx", "BlockAreaBlock", "current url", requestContext.url);
        return (
            <div className={GetBlockWrapperClasses(block, false, true)}>
                <div className='block-area-block'>
                    {block.properties.title && 
                        <h2>{block.properties.title}</h2>
                    }
                    {block.properties.blockArea &&
                        <ClientBlockList
                            blockListItems={block.properties.blockArea.items}
                            columns={block.properties.columns}
                            requestContext={requestContext}
                            previewMode={block.isPreviewMode ? block.isPreviewMode : false} />
                    }
                </div>
            </div>
        );
    }
    catch (error) {
        logError("BlockAreaBlock.tsx", 'Error when generating BlockAreaBlock', `current url: ${requestContext.url}`, 'Error', error);
    }
}
